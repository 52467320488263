import React, { useState } from 'react';
import QuantityPicker from '../CreateEvents/QuantityPicker';
import NFT from "../../Assets/Images/NFT Image.png";

const EventTitleCardBook = ({title,organizers,ticketCount,setTicketCount}) => {
    // const [quantity, setQuantity] = useState(0);

    const handleQuantityChange = (event) => {
        const { name, value } = event.target;
        console.log(event,"quantity picker")
        setTicketCount(value);
    };

    return (
        <div className='flex justify-center'>
            <div className='flex flex-col lg:flex-row w-full items-center p-4 lg:p-6 bg-[#74737333] border-[1px] border-[#636C77] rounded-xl'>
                {/* NFT Image */}
                <div className="w-32 h-32 lg:w-40 lg:h-40 bg-cover bg-center rounded overflow-hidden mb-4 lg:mb-0 lg:mr-4">
                    <img src={NFT} alt="NFT Image" className="w-full h-full" />
                </div>
                {/* Buttons and Event Details */}
                <div className="flex flex-col lg:flex-row justify-between items-center flex-grow">
                    <div className="flex flex-col">
                        {/* Buttons */}
                        <div className="flex flex-wrap gap-4 mt-2">
                            <button className='rounded-lg min-w-[8.75rem] h-[2.125rem] bg-[#B4009040] text-[#E900BA]'>NFT Included</button>
                            <button className='rounded-lg min-w-[11.25rem] h-[2.125rem] bg-[#6E33094D] text-[#F67314]'>Approval Required</button>
                        </div>
                        {/* Event Name */}
                        <div className='text-[#D5D8DC] text-2xl lg:text-3xl pt-4 lg:pb-4 pb-4 border-b-[1px] border-[#636C77]'>
                            {title}
                        </div>
                        <div className='mt-4 text-[#727C8A] font-semibold'>
                            by {organizers.map((organizer, index) => (
                            <span key={index}>
                                {organizer.name}
                                {index !== organizers.length - 1 ? (index === organizers.length - 2 ? ' &' : ', ') : ''}
                            </span>
                        ))}
                        </div>
                    </div>

                    {/* Quantity display and picker */}
                    <div className="flex flex-col mt-4 lg:mt-0 lg:ml-4">
                        <div className="text-[#727C8A] font-semibold mb-2">Quantity: {ticketCount}</div>
                        <QuantityPicker min={0} max={10} setTicketCount={setTicketCount} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventTitleCardBook;