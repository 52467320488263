import React, { useEffect, useState } from 'react'
import NavButton from '../../Components/Checkin/NavButton'
import ScanQr from '../../Components/Checkin/ScanQr'
import SearchBar from '../../Components/Checkin/SearchBar'
import { Button as ModalButton } from '../../Components/Checkin/Buttons'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dialog, DialogBody, Button } from '@material-tailwind/react'
import { useDispatch } from 'react-redux'
import { CheckinToEvent } from '../../features/Events/EventSlice'
import { Bounce, toast } from 'react-toastify'
import { fetchUserId } from '../../features/Login/UserSlice'

const Checkin = () => {

    const [open,setOpen] = useState(true)
    const navigate = useNavigate()
    const dispatch= useDispatch()

    const usePathParams = () => {
        const location = useLocation();
        const params = {};

        const pathname = location.pathname;
        const pathSegments = pathname.split('/').filter(segment => segment.includes('='));

        pathSegments.forEach(segment => {
            const [key, value] = segment.split('=');
            params[key] = value;
        });

        return params;
    };


    const params = usePathParams()
    const hasRequiredParams = params.event_id && params.ticket_id && params.user_id

    const [user,setUser] = useState({})
    useEffect(()=>{
        if(hasRequiredParams)
            {
                dispatch(fetchUserId(params.user_id)).then((result)=>{
                    if(result?.payload)
                        {
                            setUser(result?.payload?.message)
                        }
                })
            }
    },[])
    const checkinHandler = async() => {
        dispatch(CheckinToEvent(params))
        setOpen(false)
        toast('🦄 Checkin Successfully!!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
            });
        await new Promise(resolve => setTimeout(resolve, 5000)); 
        navigate("/")
    }

    const handler= ()=>{
        setOpen(!open)
    }
    return (
        <>
            {
                hasRequiredParams ?
                    <Dialog className='w-1 bg-transparent  !static' open={open} handler={handler}>
                        <DialogBody>
                            <div className='fixed z-10 inset-0 overflow-y-auto flex items-center justify-center bg-[#1E1E1E80]'>
                                <div className='border-[1px] flex flex-col bg-[#0F0C0C] border-[#282C31] h-[35vh] w-[20rem] rounded-[20px]'>
                                    <div className='flex items-center justify-between pl-4 pr-2 pb-2 border-b-[1px] border-[#282C31] pt-2'>
                                        <div className='font-gilroy text-[#D5D8DC] font-semibold'>
                                            Check In Scanner
                                        </div>
                                        <button onClick={handler} className='rounded-full w-[30px] h-[30px] bg-[#721924] bg-opacity-40 flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#C6C6C6]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                <line x1="6" y1="6" x2="18" y2="18" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className='flex flex-col justify-center gap-6 items-center mt-6'>
                                        <div className='flex flex-col justify-center items-center'>
                                            <div className='font-semibold text-center text-[#EBECEE]'>
                                                Are you sure you want to check In the {user?.username} person having email {user?.email}
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-4 justify-evenly w-full'>
                                            <button onClick={handler} className='rounded-[10px] font-gilroy text-[#A2A9B3] font-medium w-[8.125rem] h-[2.375rem] bg-[#282C31]'>
                                                Cancel
                                            </button>

                                            <Button onClick={checkinHandler} className='rounded-[10px] font-gilroy text-white font-medium w-[8.125rem] h-[2.375rem] bg-[#3EB049]'>
                                                Check-In
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogBody>
                    </Dialog> :
                    <div className='home-main'>
                        < div className='max-w-[80rem] mx-auto flex flex-col mt-8 gap-8' >
                            <div className='flex flex-row justify-between'>
                                <NavButton />
                                <ScanQr />
                            </div>

                            <div className='pb-8 border-b-[1px] border-[#282C31]'>
                                <SearchBar />
                            </div>

                            <div className='flex flex-col gap-2'>
                                <ModalButton type="checkin" /> {/* Renders a "Check In" button */}
                                <ModalButton type="checkout" /> {/* Renders a "" button */}
                                <ModalButton type="checkin" /> {/* Renders a "Check In" button */}
                                <ModalButton type="checkout" /> {/* Renders a "" button */}
                                <ModalButton type="checkin" /> {/* Renders a "Check In" button */}
                                <ModalButton type="checkout" /> {/* Renders a "" button */}
                                <ModalButton type="checkin" /> {/* Renders a "Check In" button */}
                                <ModalButton type="checkout" /> {/* Renders a "" button */}
                            </div>

                        </div >
                    </div >
            }
        </>
    )
}

export default Checkin
