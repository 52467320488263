import React from "react";
import QR from "../../Assets/Images/qr.svg";
import { useNavigate } from "react-router-dom";
import "./Scanner.css";

const Scanner = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          navigate("/checkin");
        }}
        className="scanner-main border-[1px] font-gilroy flex items-center justify-center text-xl border-[#1C4F21] bg-[#1C4F21] bg-opacity-30 w-[14.625rem] h-[3.125rem] text-[#3EB049] rounded-[12px] gap-4"
      >
        <img src={QR} alt="QR Code" className="scanner-img w-8 h-8" />
        Check In Scanner
      </div>
    </>
  );
};

export default Scanner;
