import React from 'react';
import DateImage from '../../Assets/Images/Date.png';
import map from "../../Assets/Images/map.png"
import share from "../../Assets/Images/Share.png"

const EventDate = ({ startDate, startTime, endTime,selectedEvent }) => {
    const date = new Date(startDate);
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    const month = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    return (
        <div className='flex justify-center w-full'>
            <div className=' flex flex-wrap justify-between w-full p-6 bg-[#282C31] border-[1px] bg-opacity-30 border-[#282C31] rounded-[16px]'>
                <div className="flex flex-row items-center space-x-4">
                    <div className='flex-col justify-center items-center text-center md:flex-row gap-8 border-[1px] border-[#282C31] rounded-xl p-4 bg-[#282C31] bg-opacity-30 '>
                    <p class="text-[#EBECEE] font-bold">{days[date.getDay()]}</p>
                    <p class="text-[#A2A9B3]">{date.getDate()}</p>
                    </div>
                    <div className="flex flex-col flex-grow">
                        <p class="text-[#EBECEE] font-bold">{days[date.getDay()]}, {month[date.getMonth()]} {date.getDate()}</p>
                        <p class="text-[#A2A9B3]">{startTime} to {endTime}</p>
                    </div>
                </div>
                <div className="flex flex-row items-center space-x-4 justify-between min-w-[50%]">
                    <div className='flex flex-row items-center space-x-4'>
                        <div>
                            <img src={map} alt="date" className="flex-shrink-0" />
                        </div>
                        <div className="flex flex-col flex-grow">
                            <p class="text-[#EBECEE] font-bold">{selectedEvent?.event_details?.location}</p>
                            <p class="text-[#A2A9B3]">{startTime} to {endTime}</p>
                        </div>
                    </div>
                    <img src={share} alt="date" className=" flex-shrink-0" />
                </div>
            </div>
        </div>
    );
}

export default EventDate;