import React, { useState, useEffect } from "react";
import "./EventInfoForm.css";

const SpeakerDetails = ({ speakerDetails, setSpeakerDetails, imageUpload }) => {
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const validateInput = (name, value, type = "text") => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) {
          error = "Speaker name is required";
        }
        break;
      case "designation":
        if (!value) {
          error = "Designation is required";
        }
        break;
      case "img_url":
        if (type === "file" && !value) {
          error = "Image is required";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleInputChange = async (event, index) => {
    const { name, value, type, files } = event.target;
    if (type === "file" && files.length > 0) {
      const fileUrl = await imageUpload(files[0]);
      validateInput(name, fileUrl, type);
      setSpeakerDetails((prevSpeakers) => {
        const updatedSpeakers = [...prevSpeakers];
        updatedSpeakers[index] = {
          ...updatedSpeakers[index],
          [name]: fileUrl,
        };
        return updatedSpeakers;
      });
    } else {
      validateInput(name, value, type);
      setSpeakerDetails((prevSpeakers) => {
        const updatedSpeakers = [...prevSpeakers];
        updatedSpeakers[index] = {
          ...updatedSpeakers[index],
          [name]: value,
        };
        return updatedSpeakers;
      });
    }
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBlur = (event, index) => {
    const { name, value, type } = event.target;
    validateInput(name, value, type);
  };

  useEffect(() => {
    const checkErrors = Object.values(errors).some((error) => error !== "");
    setHasErrors(checkErrors);
  }, [errors]);

  const addSpeakers = (e) => {
    e.preventDefault();
    setSpeakerDetails((prevSpeakers) => {
      const newSpeaker = { name: "", profile_link: "", img_url: "" };
      return [...prevSpeakers, newSpeaker];
    });
  };

  return (
    <form>
      <div className="info-main-div flex flex-col py-5 mt-5 w-full rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:max-w-full">
        <div className="flex flex-col px-9 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex h-[38px] w-full mb-2 justify-between items-center">
            <label htmlFor="organisers">Add Speakers*</label>
            <button
              onClick={addSpeakers}
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-[#727C8A] bg-opacity-30 rounded-xl dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                className="me-2"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#515861"
                />
                <path
                  d="M14 9.10688V10.9101H6V9.10688H14ZM10.9101 14H9.10688V6H10.9101V14Z"
                  fill="#D5D8DC"
                />
              </svg>
              Add More
            </button>
          </div>
          <div className="grid-cols-2 grid gap-4">
            {speakerDetails.map((value, index) => (
              <div
                key={index}
                className="selector-drop w-full border rounded-xl p-2 h-full border-[#727C8A30] bg-[#282C31] bg-opacity-30"
              >
                <div className="flex flex-col items-center justify-center w-full h-20 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  {/* <input
                                        id="dropzone-file"
                                        name="img_url"
                                        onChange={(e) => handleInputChange(e, index)}
                                        onFocus={handleFocus}
                                        onBlur={(e) => handleBlur(e, index)}
                                        type="file"
                                    />
                                    <div className="flex flex-col items-center justify-center pt-5 pb-5">
                                        <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                    </div> */}
                  <input
                    id="dropzone-file"
                    name="img_url"
                    onChange={(e) => handleInputChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, index)}
                    type="file"
                  />
                  {errors.img_url && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.img_url}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={value?.name}
                    onChange={(e) => handleInputChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, index)}
                    placeholder="Enter Speaker Name"
                    className={`bg-transparent h-[46px] w-full justify-left items-start px-6 py-2 mt-2 text-sm text-left text-[#727C8A] rounded-xl border border-solid max-md:px-5 ${
                      errors.name
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.name && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    value={value?.designation}
                    onChange={(e) => handleInputChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, index)}
                    placeholder="Designation"
                    className={`bg-transparent h-[46px] w-full justify-left items-start px-6 py-2 mt-2 text-sm text-left text-[#727C8A] rounded-xl border border-solid max-md:px-5 ${
                      errors.designation
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.designation && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.designation}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default SpeakerDetails;
