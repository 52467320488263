import React from 'react';
import { Avatar } from "@material-tailwind/react";

const AvatarStack = () => {
    return (
        <div className="flex items-center -space-x-4">
            <Avatar
                variant="circular"
                alt="user 1"
                className="border-2 border-[#727C8A] hover:z-10 focus:z-10 max-h-8 max-w-8"
                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
            />
            <Avatar
                variant="circular"
                alt="user 2"
                className="border-2 border-[#727C8A] hover:z-10 focus:z-10 max-h-8 max-w-8"
                src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
            />
        </div>
    );
}

export default AvatarStack;
