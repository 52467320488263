import React, { useEffect, useState } from 'react';
import { Button, Input } from '@material-tailwind/react';
import login from "../../Assets/SVG/login.svg";
import google from "../../Assets/Login/google.svg";
import mobile from "../../Assets/Login/mobile.svg";
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useDispatch } from 'react-redux';
import { googleLoginUser, loginOtp, loginUser, profile } from '../../features/Login/UserSlice';
import { useAccount, useSignMessage, useChainId } from "wagmi";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getMessage, verifySignature } from '../../features/Login/UserSlice';
import wallet from "../../Assets/Images/wallet.png"

const LoginContainer = ({ phone, setPhone }) => {
  const { openConnectModal } = useConnectModal();
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(window.location.pathname === "/googleauth")
  const dispatch = useDispatch()



  const { isConnected, address } = useAccount();
  const chain = useChainId();
  const [status, setStatus] = useState('unauthenticated');
  const { signMessageAsync } = useSignMessage();

  useEffect(() => {
    const handleAuth = async () => {
      const message = await getMessage({
        "address": address,
        "chain": chain,
        "network": "evm"
      })

      const signature = await signMessageAsync(message);

      dispatch(verifySignature(
        {
          "message": message.message,
          "signature": signature,
          "network": "evm"
        })).then((res) => {
          console.log(res)
          dispatch(profile())
          setStatus("authenticated")
        })

    };
    if (status === "unauthenticated" && isConnected && localStorage.getItem("Authorization") === null) {
      handleAuth();
    }
  }, [status, isConnected]);

  const eventChangeHandler = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value)
    }
    else {
      setPhoneNumber(event.target.value)
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get("code") !== null && window.location.pathname === "/googleauth") {
      dispatch(googleLoginUser({
        code: searchParams.get("code")
      })).then((result) => {
        if (result.payload) {
          dispatch(profile())
        }
        navigate("/")
        window.location.reload()
      })
    }
  }, [])
  const GoogleLogin = () => {
    setPhone(!phone)
  }


  const loginHandler = () => {
    setLoading(true)
    if (!phone) {
      const userCredentials = {
        "email": email,
        "password": "username"
      }
      dispatch(loginUser(userCredentials)).then((result) => {
        setLoading(false)
        if (result.payload) {
          dispatch(profile())
        }
        else {
          console.error("Login Failed")
        }
      })
    }
    else {
      dispatch(loginOtp({
        "mobile": phoneNumber
      })).then((result) => {
        if (result.payload) {
          dispatch(profile())
        }
      })
    }
  }

  return (
    <div className='flex flex-wrap justify-center'>
      <div className='z-100 min-w-64 w-full lg:w-1/2 relative p-4 flex flex-col gap-6 justify-center pr-6'>
        <Button className='px w-20'>
          <img src={login} alt="login" />
        </Button>
        <div>
          <p className='text-xl text-gray-300 font-semibold'>Welcome to ABC</p>
          <p className='text-sm text-gray-500'>Please sign in or sign up below</p>
        </div>
        {/* {!phone ? */}
          <>
            <label htmlFor="Email" className='text-sm text-gray-500'>Email</label>
            <Input value={email} onChange={eventChangeHandler} type="email" color='white' name="email" label="Email Address" className='text-white' placeholder='Email' />
          </> 
          {/* // <>
          //   <label htmlFor="Phone Number" className='text-sm text-gray-500'>Phone Number</label>
          //   <Input value={phoneNumber} onChange={eventChangeHandler} type="phone" color='white' name="phone" label="Phone Number" className='text-white' placeholder='Phone Number' />
          // </> */}
        {/* } */}
        <Button loading={loading} onClick={loginHandler} className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 normal-case'>Continue With Login</Button>
      </div>
      <div className='flex min-w-64 flex-col gap-6 p-4 w-full lg:w-1/2 justify-center items-center pl-6'>
        <p className='text-x font-semibold text-gray-300'>Or Continue With</p>
        {/* {!phone ?
          <Button loading={loading} onClick={GoogleLogin} style={{ background: 'linear-gradient(to top, #161515 3.23%, #1E1E1E 107.94%)' }} className='w-full text-gray-500'>Sign in with Phone</Button> :
          <Button loading={loading} onClick={GoogleLogin} style={{ background: 'linear-gradient(to top, #161515 3.23%, #1E1E1E 107.94%)' }} className='w-full text-gray-500'>Sign in with Email</Button>} */}
        <Button onClick={() => {
          window.location = "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&include_granted_scopes=true&response_type=code&client_id=544526412101-vcpujhm6d0695l8bgmc6kqa314fscdn6.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fgoogleauth"
        }} loading={loading} style={{ background: 'linear-gradient(to top, #161515 3.23%, #1E1E1E 107.94%)' }} className='flex text-gray-500 items-center gap-3 justify-center w-full'>
          <img src={google} alt="metamask" className="h-6 w-6" />
          Sign in with Gmail
        </Button>
        <Button style={{ background: 'linear-gradient(to top, #161515 3.23%, #1E1E1E 107.94%)' }} className='flex items-center text-gray-500 gap-3 justify-center w-full' onClick={openConnectModal}>
          <img src={wallet} alt="metamask" className="h-6 w-6" />
          Connect Wallet
        </Button>
      </div>
    </div>
  );
}

export default LoginContainer;