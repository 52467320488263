import React, { useEffect } from "react";
import {
  Card,
  Typography,
} from "@material-tailwind/react";
import userImg from "../../Assets/Images/EventAttendees/Image.jpg"
import { fetchAttendees } from "../../features/Events/EventSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EventAttendees = ({id}) => {

    const dispatch = useDispatch()
    const {attendees} = useSelector((state) => state.events)
    const navigate = useNavigate()
    useEffect(()=>{
        dispatch(fetchAttendees()).then((result)=>{
            console.log(result)
        })
        console.log(attendees)
    },[])
  return (
    <Card className="border-[#282C31] border-[1px] bg-opacity-30 mb-4 rounded-xl bg-[#282C31] min-h-20">
      <div className="p-4 w-full flex items-center justify-between pb-2 border-b-2 border-[#282C31]">
        <Typography variant="lead" className="text-[#EBECEE] text-lg lg:text-sm xl:text-lg font-bold">
            Event Attendees
        </Typography>
        {/* <Typography variant="lead" className="text-[#FF68D5] hover:text-[#EC3BBB] text-sm font-bold flex items-center underline cursor-pointer">
            View all
        </Typography> */}
      </div>
      
      <div className="p-4 flex flex-col justify-between gap-5">

{attendees?.map((value,index)=>{
    if(index<5|| index> attendees?.length-4)
        {
            return(
                <div key={index} className="flex justify-between items-center">
                    <div className="flex flex-row gap-2 items-center justify-center">
                        <div style={{width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #B700FF"}}>
                            <img src={userImg} alt="user" style={{borderRadius: "inherit", width: "100%", height: "100%"}}/>
                        </div>
                        <div className="text-[#D5D8DC] text-sm lg:text-[15px] font-medium">
                            {value?.username}
                        </div>
                    </div>
                    <div onClick={()=>{
                        navigate(`/profileInfo/${value?.username}`)
                    }} className="bg-[#515861] hover:bg-[#515861] bg-opacity-40 rounded-[8px] text-[#BDC2C9] hover:text-[#EBECEE] text-center font-normal px-4 py-1.5 lg:px-3 xl-px-4 text-[12px] cursor-pointer">
                        View Profile
                    </div>
                </div>
            )
        }
        else{
            return ""
        }
})}
        
      

      </div>

    </Card>
  );
};

export default EventAttendees;
