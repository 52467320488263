import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input,
  Textarea
} from "@material-tailwind/react";
import { useDispatch } from 'react-redux';
import { updateProfile, profile } from '../../features/Login/UserSlice';

const ProfileForm = ({setEdit}) => {
  const [profileData, setProfileData] = useState(null);
  const getProfile = async () => {
    const response = await dispatch(profile());
    if (profile.fulfilled.match(response)) {
        console.log(response.payload.user,"profile data")
        setProfileData(response.payload.user);
    } else {
        const error = response.error;
        console.error("Profile request failed:", error);
        throw new Error("Profile request failed");
    }
  };

  useEffect(() => {
    getProfile()
  }, [])



  const [form, setForm] = useState({
    username: "",
    full_name: "",
    designation: "",
    about_me: "",
    location: "",
    email: "",
    contact_number: "",
    websiteURL: "",
    facebookURL: "",
    twitterURL: "",
    linkedInURL: "",
    telegramURL: "",
    discordURL: ""
  });

  useEffect(() => {
    if (profileData !== null) {
      setForm(prevState => ({
        ...prevState,
        username: profileData.hasOwnProperty("username") && profileData["username"].length>0 ? profileData["username"] : "",
        full_name: profileData.hasOwnProperty("full_name") && profileData["full_name"].length>0 ? profileData["full_name"] : "",
        designation: profileData.hasOwnProperty("designation") && profileData["designation"].length>0 ? profileData["designation"] : "",
        about_me: profileData.hasOwnProperty("about_me") && profileData["about_me"].length>0 ? profileData["about_me"] : "",
        location: profileData.hasOwnProperty("location") && profileData["location"].length>0 ? profileData["location"] : "",
        websiteURL: profileData.hasOwnProperty("websiteURL") && profileData["websiteURL"].length>0 ? profileData["websiteURL"] : "",
        facebookURL: profileData.hasOwnProperty("facebookURL") && profileData["facebookURL"].length>0 ? profileData["facebookURL"] : "",
        twitterURL: profileData.hasOwnProperty("twitterURL") && profileData["twitterURL"].length>0 ? profileData["twitterURL"] : "",
        linkedInURL: profileData.hasOwnProperty("linkedInURL") && profileData["linkedInURL"].length>0 ? profileData["linkedInURL"] : "",
        telegramURL: profileData.hasOwnProperty("telegramURL") && profileData["telegramURL"].length>0 ? profileData["telegramURL"] : "",
        discordURL: profileData.hasOwnProperty("discordURL") && profileData["discordURL"].length>0 ? profileData["discordURL"] : "",
        email: profileData.hasOwnProperty("email") && profileData["email"].length>0 ? profileData["email"] : "",
        contact_number: profileData.hasOwnProperty("phone_number") && profileData["phone_number"].length>0 ? profileData["phone_number"] : "",
      }));
    }
  }, [profileData]);


  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveChanges = async()=>{
    console.log(form)
    try {
      const response = await dispatch(updateProfile(form));
      if (updateProfile.fulfilled.match(response)) {
        console.log('Profile updated successfully:', response.payload);
        setEdit(false)
      } else {
        console.error('Failed to update profile:', response.error);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  }

  return (
    <div className='flex flex-col mt-16 gap-8 mb-8 items-center'>

      <div className='flex text-white flex-col justify-center items-center sm:max-w-[35rem] w-[88vw] bg-[#282C31] rounded-[20px] bg-opacity-30 pt-8 px-4'>
        <div className='flex flex-col mb-4 border-b-[1px] border-[#282C31] gap-4 w-full'>
          <div className='flex flex-col gap-2'>
            <p className='text-[#BDC2C9] font-semibold text-sm'>Full Name</p>
            <input
              type="text"
              name="full_name"
              value={form.full_name}
              onChange={handleChange}
              className="bg-[#282C31] px-2 bg-opacity-40 border-[1px] border-[#727C8A] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none"
            />
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-[#BDC2C9] font-semibold text-sm'>User Name</p>
            <input
              type="text"
              name="username"
              value={form.username}
              onChange={handleChange}
              className="bg-[#282C31] px-2 bg-opacity-40 border-[1px] border-[#727C8A] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none"
            />
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-[#BDC2C9] font-semibold text-sm'>Designation</p>
            <input
              type="text"
              name="designation"
              value={form.designation}
              onChange={handleChange}
              className="bg-[#282C31] px-2 bg-opacity-40 border-[1px] border-[#727C8A] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none"
            />
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-[#BDC2C9] font-semibold text-sm'>About Me</p>
            <textarea
              name="about_me"
              value={form.about_me}
              onChange={handleChange}
              className="bg-[#282C31] px-2 py-1 bg-opacity-40 border-[1px] border-[#727C8A] rounded-[12px] w-full h-[9.2rem] focus:ring-0 focus:outline-none"
            />
          </div>
          <div className='flex flex-col gap-2 mb-8'>
            <p className='text-[#BDC2C9] font-semibold text-sm'>Add Location</p>
            <input
              type="text"
              name="location"
              value={form.location}
              onChange={handleChange}
              className="bg-[#282C31] px-2  bg-opacity-40 border-[1px] border-[#727C8A] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 pb-8 w-full">
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M3 12H21M3 12C3 16.9706 7.02944 21 12 21M3 12C3 7.02944 7.02944 3 12 3M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M12 21C4.75561 13.08 8.98151 5.7 12 3M12 21C19.2444 13.08 15.0185 5.7 12 3" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
            <input
              type="text"
              name="websiteURL"
              value={form.websiteURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clipRule="evenodd" />
              </svg>
            </span>
            <input
              type="text"
              name="facebookURL"
              value={form.facebookURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
              </svg>
            </span>
            <input
              type="text"
              name="twitterURL"
              value={form.twitterURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clipRule="evenodd" />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </span>
            <input
              type="text"
              name="linkedInURL"
              value={form.linkedInURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M29.919 6.163l-4.225 19.925c-0.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-0.344 0.344-0.631 0.631-1.294 0.631l0.463-6.556 11.931-10.781c0.519-0.462-0.113-0.719-0.806-0.256l-14.75 9.288-6.35-1.988c-1.381-0.431-1.406-1.381 0.288-2.044l24.837-9.569c1.15-0.431 2.156 0.256 1.781 2.013z" />
              </svg>
            </span>
            <input
              type="text"
              name="telegramURL"
              value={form.telegramURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex flex-1 rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg className="w-6 h-6 text-black dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
              </svg>
            </span>
            <input
              type="text"
              name="discordURL"
              value={form.discordURL}
              onChange={handleChange}
              placeholder="https://www.abc_events.com/"
              className="bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="sm:max-w-[35rem] w-[88vw]">
        <Card className="w-[full] bg-[#282C31] border-profile bg-opacity-40 rounded-[20px]">
          <CardBody>
            <div className="flex items-center justify-between mt-8 ml-8">
              <Typography variant="h5" color="white" className="mb-2">
                <span className='text-[#C568FF]'>Personal Information</span>
              </Typography>
            </div>
            <form className="mt-6 mb-2 w-11/12 m-auto">
              <div className="mb-1 flex flex-col gap-6">
                <Typography variant="h6" className="-mb-3 text-[#BDC2C9]">
                  Email
                </Typography>
                <Input
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  className="h-14 bg-transparent border-b-[1px] border-[#727C8A] text-white"
                  placeholder='Enter the Email Address'
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
                <Typography variant="h6" className="-mb-3 text-[#BDC2C9]">
                  Contact Number
                </Typography>
                <Input
                  name="contact_number"
                  value={form.contact_number}
                  onChange={handleChange}
                  placeholder='Enter the contact Number'
                  className="h-14 bg-transparent border-b-[1px] border-[#727C8A] text-white"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
                <div className='border-b-[1px] border-[#282C31]'></div>
              </div>
              <Button
                className='normal-case text-lg mt-10 font-bold rounded-[12px]'
                fullWidth
                style={{
                  background: 'linear-gradient(90deg, #A700E9 0%, #E900BA 100%)',
                  boxShadow: '0px 4px 2px 0px #00000040 inset'
                }}
                onClick={saveChanges}
              >
                Save Changes
              </Button>
            </form>
          </CardBody>
          <CardFooter className="pt-0"></CardFooter>
        </Card>
      </div>
    </div>
  );
}

export default ProfileForm;