import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchReferralHistory = createAsyncThunk('rewards/fetchReferralHistory',
    async () => {
        try {
            const response = await makeRequest("GET", `apps/referrals/fetch_referral_history/`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

const rewardsSlice = createSlice({
    name: "rewards",
    initialState: {
        status: null,
        error: null,
        referralHistory: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchReferralHistory.fulfilled, (state, action) => {
            state.referralHistory = action.payload
        })
    }
})

export default rewardsSlice.reducer
