import React from 'react';
import Avatar from '../../Components/Card/Avatar';


const EventTitleCard = ({ title, organizers }) => {
    return (
        <div className='flex justify-center'>
            <div className='flex flex-col w-full p-4 lg:p-6 bg-[#282C31] bg-opacity-30 border-[1px] border-[#282C31] rounded-[16px]'>
                <div className="flex flex-col lg:flex-row gap-4">
                    {/* Buttons */}
                    <div className="flex flex-wrap gap-4">
                        <button className='rounded-lg min-w-[8.75rem] h-[2.125rem] bg-[#B4009040] text-[#E900BA]'>NFT Included</button>
                        <button className='rounded-lg min-w-[11.25rem] h-[2.125rem] bg-[#6E33094D] text-[#F67314]'>Approval Required</button>
                    </div>
                </div>
                {/* Event Name */}
                <div className='text-[#D5D8DC] font-gilroy text-2xl lg:text-3xl pt-4 lg:pb-4 pb-4 border-b-[1px] border-[#282C31]'>
                    {title}
                </div>

                <div className='flex items-center gap-4 mt-4'>
                    <Avatar />
                    <div className='text-[#727C8A] font-semibold'>
                        by {organizers.map((organizer, index) => (
                            <span key={index}>
                                {organizer.name}
                                {index !== organizers.length - 1 ? (index === organizers.length - 2 ? ' &' : ', ') : ''}
                            </span>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default EventTitleCard;