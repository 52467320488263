import React from 'react';
import left from '../../Assets/Images/arrow-left.svg';

const NavButton = () => {
    return (
        <>
            <div className="flex flex-row gap-8 items-center"> {/* Added justify-center to horizontally center */}
                <img src={left} alt='left' style={{ height: '2rem' }} />
                <div className='text-[#D5D8DC] font-gilroy text-2xl font-semibold'>Check in Scanner</div>
            </div>
        </>
    );
}

export default NavButton;

// import React from 'react';

// const NavButton = () => {
//     return (
//         <>
//             <div className="">

//             </div>
//         </>
//     );
// }

// export default NavButton;