import React, { useEffect, useState, useLayoutEffect } from 'react'
import TopBar from '../../Components/EventInfo/Topbar'
import EventCover from "../../Assets/Images/EventCover.png"
import EventTitleCard from '../../Components/EventInfo/EventTitleCard'
import EventBookNow from '../../Components/EventInfo/EventBookNow'
import { EventDetailTabs } from '../../Components/EventInfo/EventDetailTabs'
import { EventNftMintCard } from '../../Components/EventInfo/EventNftMintCard'
import EventDate from '../../Components/EventInfo/EventDate'
import TicketCheckoutForm from '../../Components/form/TicketCheckoutForm'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSelectedEvent } from '../../features/Events/EventSlice'
import { Skeleton } from '../../Components/Skeleton/Skeleton'
import EventPaymentCard from '../../Components/EventInfo/EventPaymentCard'
import EventTitleCardBook from '../../Components/EventInfo/EventTitleCardBook'
import EventAttendees from '../../Components/EventInfo/EventAttendees'

const EventInfo = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [form,setForm] = useState({firstName:"",lastName:"",emaiId:"",contactNumber:"", walletAddress:""})
    const showEventBookNow = windowWidth >= 1024;
    const [paymentStarted, setPayment] = useState(false)
    const [ticketCount, setTicketCount] = useState()
    const { _id } = useParams()
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { selectedEvent } = useSelector((state) => state.events)
    useEffect(() => {
        dispatch(fetchSelectedEvent(_id)).then((result) => {
            console.log(result)
        })
    }, [])

    if (selectedEvent === null) {
        return (
            <Skeleton />
        )
    }

    return (
        <main>
            <div className="px-4 pb-8">
                <div className="max-w-[80rem] mx-auto">
                    <TopBar />
                    {!paymentStarted ?
                        <>
                            <div className="px-4 pb-8">
                                <div className="grid min-h-[140px] w-full mx-auto mb-8 mt-6 place-items-center lg:overflow-visible">
                                    <div className="overflow-x-auto lg:overflow-hidden rounded-lg">
                                        <img
                                            className="object-fill object-center w-full lg:h-auto rounded-lg shadow-xl h-70 shadow-blue-gray-900/50"
                                            src={selectedEvent?.[0]?.event_details?.banner_url}
                                            alt={EventCover}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col-reverse lg:flex-row w-full lg:w-[full] mx-auto justify-between gap-8">
                                    <div className="w-full lg:w-3/4 flex flex-col gap-5">
                                        <EventDate selectedEvent={selectedEvent?.[0]} startDate={selectedEvent?.[0]?.basic_info?.start_date} startTime={selectedEvent?.[0]?.basic_info?.start_time} endTime={selectedEvent?.[0]?.basic_info?.end_time} />
                                        <EventTitleCard title={selectedEvent?.[0]?.basic_info?.title} organizers={selectedEvent?.[0]?.event_details?.organizer} />
                                        {showEventBookNow && <EventBookNow tickets={selectedEvent?.[0]?.ticket_types} setPayment={setPayment} />}
                                        <EventDetailTabs event={selectedEvent?.[0]} />
                                    </div>
                                    <div className="w-full lg:w-1/4">
                                        <EventNftMintCard nft_image={selectedEvent?.[0]?.ticket_types?.[0]?.nft_image} />
                                        <EventAttendees id={selectedEvent?.[0]?._id}/>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <div className="px-4 pb-8">
                            <div className='flex flex-col-reverse gap-4 lg:flex-row w-full max-w-[80rem] mx-auto justify-between lg:gap-8'>
                                <div className='w-full lg:w-3/4 flex flex-col gap-5'>
                                    <EventTitleCardBook title={selectedEvent?.[0]?.basic_info?.title} ticketCount={ticketCount} setTicketCount={setTicketCount} organizers={selectedEvent?.[0]?.event_details?.organizer} />
                                    <TicketCheckoutForm setForm={setForm} form={form}/>
                                </div>
                                <div className='w-full lg:w-1/4 mt-8 lg:mt-0'>
                                    <EventPaymentCard tickets={selectedEvent?.[0]?.ticket_types} ticketCount={ticketCount} form={form} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </main>

    )
}

export default EventInfo