import React from 'react'
import HomeContainer from '../../container/HomeContainer.jsx/HomeContainer'


const MyEvents = () => {
  return (
    <main>
        <div className='HomescrollContainer' style={{height:"88vh"}}>
        <HomeContainer type={"purchased"}/>
        </div>
    </main>
  )
}

export default MyEvents