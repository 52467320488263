import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Dialog,
  DialogBody,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import Linkedin from "../../Assets/SVG/Linked in.svg";
import Twitter from "../../Assets/SVG/Twitter.svg";
import Telegram from "../../Assets/SVG/Telegram.svg";
import "./ProfileInfoCard.css";
import QRCode from "react-qr-code";
import { useState } from "react"

const ProfileInfoCard = ({ profileData }) => {

  const [open, setOpen] = useState(false)
  return (
    <Card className="mt-6 w-[88vw] md:w-3/4 lg:w-[40%] bg-profile border-profile bg-opacity-40 p-4">
      <CardBody>
        <Typography variant="h5" color="white" className="mb-2">
          <span>{profileData?.full_name}</span>
        </Typography>
        <Typography color="white" className="mb-2 font-public-sans">
          <span style={{ color: "#C568FF" }}>{profileData?.designation}</span>
        </Typography>
        <Typography color="white" className="mt-2 font-public-sans">
          <span style={{ color: "#C568FF" }}>About me</span>
        </Typography>
        <Typography color="white" className="font-public-sans text-sm">
          {profileData?.about_me}
        </Typography>
      </CardBody>
      <CardFooter className="pt-0 border-t-profile border-t-2">
        <div className="social-icons flex flex-col  sm:flex-row gap-4 mt-4 items-center">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              window.location.href = profileData.linkedInURL;
            }}
            className="sm:h-[5vh] rounded-full bg-[#680053]"
          >
            <img src={Linkedin} alt="linkedin" />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              window.location.href = profileData.twitterURL;
            }}
            className="rounded-full bg-[#680053]"
          >
            <img src={Twitter} alt="twitter" />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              window.location.href = profileData?.telegramURL;
            }}
            className="rounded-full bg-[#680053]"
          >
            <img src={Telegram} alt="telegram" />
          </IconButton>
          <Typography color="white" className="font-public-sans text-sm">
            <span>{profileData?.location}</span>
          </Typography>
          <Button className="ml-auto mt-4 sm:mt-0" onClick={() => setOpen(true)}>Share</Button>
          <Dialog open={open} className='LoginCard !static' size='sm' handler={() => setOpen(false)}>
            <DialogBody>
            <div className='font-gilroy text-[#D5D8DC] text-center mb-5 font-semibold'>
              Share Your Profile
              </div>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${window.location.origin}/profileInfo/${profileData?.username}`}
                viewBox={`0 0 256 256`}
              />
              <div className='font-gilroy text-[#D5D8DC] text-center mt-5 font-semibold'>
              {`${window.location.origin}/profileInfo/${profileData?.username}`}
              </div>
            </DialogBody>
          </Dialog>
        </div>
      </CardFooter>
    </Card>
  );
};

export default ProfileInfoCard;
