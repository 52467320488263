import React, { useState } from "react";
import sms from "../../Assets/Images/CreateEvents/sms.svg";
import person from "../../Assets/Images/CreateEvents/person.svg";
import ticket from "../../Assets/Images/CreateEvents/ticket-2.svg";
import layout from "../../Assets/Images/CreateEvents/layout.svg";
import setting from "../../Assets/Images/CreateEvents/setting-2.svg";
import book from "../../Assets/Images/CreateEvents/book.svg";
import { useNavigate } from "react-router-dom";
import EventListMobile from "./EventListMobile"; // Import the EventListMobile component

const TopBar = () => {
  const [activeTab, setActiveTab] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    if (tab === "Overview") {
      // Check if the tab is Overview
      navigate(`./${tab}`);
      setActiveTab(tab);
      return;
    } else if (window.innerWidth < 768) {
      // Check if the screen size is mobile
      setIsSidebarOpen(true); // Open sidebar on mobile
    } else {
      navigate(`./${tab}`);
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className="flex px-2 m-auto flex-row mt-2 justify-between items-center bg-[#282C31] bg-opacity-30 h-[3.5rem] border-[#636C77] border-[1px] rounded-[16px] max-w-[80rem] w-full">
        <div className="flex flex-row  justify-center md:justify-start">
          <p
            className={`text-base font-normal text-[#BDC2C9] flex items-center justify-center px-2 md:px-4 lg:px-8 h-10 rounded-[14px] ${
              activeTab === "Overview"
                ? "bg-[#282C31] bg-opacity-40"
                : "hover:bg-[#282C31] hover:bg-opacity-40"
            } transition-opacity duration-300`}
            onClick={() => handleTabClick("Overview")}
          >
            <img src={layout} alt="Overview" className="mr-2 w-5 h-5" />{" "}
            Overview
          </p>
          <p
            className={`text-base font-normal text-[#BDC2C9] flex items-center justify-center px-2 md:px-4 lg:px-8 h-10 rounded-[14px] ${
              activeTab === ""
                ? "bg-[#282C31] bg-opacity-40"
                : "hover:bg-[#282C31] hover:bg-opacity-40"
            } transition-opacity duration-300`}
            onClick={() => handleTabClick("")}
          >
            <img src={ticket} alt="Events" className="mr-2 w-5 h-5" /> Events
          </p>
          <p
            className={`text-base font-normal text-[#BDC2C9] flex items-center justify-center px-2 md:px-4 lg:px-8 h-10 rounded-[14px] ${
              activeTab === "Contacts"
                ? "bg-[#282C31] bg-opacity-40"
                : "hover:bg-[#282C31] hover:bg-opacity-40"
            } transition-opacity duration-300`}
            onClick={() => handleTabClick("Contacts")}
          >
            <img src={person} alt="Contacts" className="mr-2 w-5 h-5" />{" "}
            Contacts
          </p>
          <p
            className={`text-base font-normal text-[#BDC2C9] flex items-center justify-center px-2 md:px-4 lg:px-8 h-10 rounded-[14px] ${
              activeTab === "Campaigns"
                ? "bg-[#282C31] bg-opacity-40"
                : "hover:bg-[#282C31] hover:bg-opacity-40"
            } transition-opacity duration-300`}
            onClick={() => handleTabClick("Campaigns")}
          >
            <img src={sms} alt="Campaigns" className="mr-2 w-5 h-5" /> Campaigns
          </p>
          <p
            className={`text-base font-normal text-[#BDC2C9] flex items-center justify-center px-2 md:px-4 lg:px-8 h-10 rounded-[14px] hidden md:flex ${
              activeTab === "Settings"
                ? "bg-[#282C31] bg-opacity-40"
                : "hover:bg-[#282C31] hover:bg-opacity-40"
            } transition-opacity duration-300`}
            onClick={() => handleTabClick("Settings")}
          >
            <img src={setting} alt="Settings" className="mr-2 w-5 h-5" />{" "}
            Settings
          </p>
        </div>
        <div className="hidden md:flex">
          <p className="text-base flex font-normal text-[#BDC2C9] mr-2 md:mr-4">
            <img src={book} alt="Help" className="mr-2 w-5 h-5" /> Help
          </p>
        </div>
      </div>
      {isSidebarOpen && window.innerWidth < 768 && (
        <div className="fixed inset-0 z-50 flex">
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setIsSidebarOpen(false)}
          ></div>
          <div className="relative bg-[#74737333] border-[#282C31] border-[1px] rounded-2xl w-[18.125rem] h-full z-50">
            <EventListMobile closeSidebar={() => setIsSidebarOpen(false)} />
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
