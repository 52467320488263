import React from 'react';

const Modal = ({ showModal, setShowModal }) => {
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <div className='fixed z-10 inset-0 overflow-y-auto flex items-center justify-center bg-[#1E1E1E80]'>
                    <div className='border-[1px] flex flex-col bg-[#0F0C0C] border-[#282C31] h-[13.125rem] w-[20rem] rounded-[20px]'>
                        <div className='flex items-center justify-between pl-4 pr-2 pb-2 border-b-[1px] border-[#282C31] pt-2'>
                            <div className='font-gilroy text-[#D5D8DC] font-semibold'>
                                Check In Scanner
                            </div>
                            <button onClick={handleCloseModal} className='rounded-full w-[30px] h-[30px] bg-[#721924] bg-opacity-40 flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#C6C6C6]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                        </div>

                        <div className='flex flex-col justify-center gap-6 items-center mt-6'>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='font-semibold text-[#EBECEE]'>
                                    zandhiyaa Sans
                                </div>
                                <div className='text-[#EBECEE]'>
                                    sandhiya2020@gmail.com
                                </div>
                            </div>

                            <div className='flex flex-row gap-4 justify-evenly w-full'>
                                <button className='rounded-[10px] font-gilroy text-[#A2A9B3] font-medium w-[8.125rem] h-[2.375rem] bg-[#282C31]'>
                                    Cancel
                                </button>

                                <button className='rounded-[10px] font-gilroy text-[#143817] font-medium w-[8.125rem] h-[2.375rem] bg-[#3EB049]'>
                                    Check-In
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
