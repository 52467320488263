import React from 'react'
import TopBar from '../../Components/CreateEvents/TopBar'
import { Route, Routes } from 'react-router-dom'
import EventDashboard from '../../container/EventDashboardContainer/EventDashboard'
import EventFormContainer from '../../container/EventFormContainer/EventFormContainer'
import EventOverview from '../../container/EventOverviewCongtainer/EventOverview'

const Events = () => {
  return (
    <main>
      <TopBar />
      <Routes>
        <Route path='/Overview' element={<EventOverview/>}/>
        <Route path='/*' element = {<EventFormContainer/>}/>
      </Routes>

    </main>
  )
}

export default Events