import React from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Carousel,
    Avatar,
    Typography,
} from "@material-tailwind/react";
import Card from "../Card/Card";

import {
    GoogleMap,
    Marker,
    LoadScript,
    Polygon,
    Polyline,
} from "@react-google-maps/api";


const EventDetails = ({description}) => {
    return (
        <div className=" border-[#636C77] text-white  mt-2 mb-4 rounded-xl p-4 bg-[#74737333]">
            <div>
                {
                description?
                description:
                <>No description found</>
                }
                {/* <span>Event Details</span>
                <span>
                    Join us as Rohit shares his journey with Toplyne and CleverTap, delving into the often-unseen challenges of scaling a SaaS company. From initial market fit struggles to navigating funding and growth stages, he brings a wealth of knowledge and experience.
                </span>
                <span>
                    Key Description Points:
                    <li>
                        Identifying and overcoming early-stage growth hurdles in the SaaS landscape.
                    </li>
                    <li>
                        Identifying and overcoming early-stage growth hurdles in the SaaS landscape.
                    </li>
                </span>
                <span>
                    Whether you're an emerging SaaS founder or an industry enthusiast, this session is packed with actionable insights and strategies tailored for building SaaS from India.
                </span> */}
            </div>
        </div>
    )
}


const SubEvents = () => {
    const data={
        "basic_info": {
          "title": "Sample Side Event",
          "start_date": "2024-05-01",
          "end_date": "2024-05-03",
          "start_time": "09:00",
          "end_time": "17:00",
          "event_slug": "sample-side-event-3",
          "timezone": "UTC+0",
          "currency": "USD",
          "social_media_links": {
            "telegram": "https://t.me/sample_event",
            "twitter": "https://twitter.com/sample_event",
            "website": "https://sampleevent.com",
            "facebook": "https://www.facebook.com/sampleevent",
            "linkedIn": "https://www.linkedin.com/company/sampleevent",
            "discord": "https://discord.gg/sampleevent"
          }
        },
        "event_details": {
          "description": "This is a sample event description.",
          "featured_img_url": "https://storage.googleapis.com/abc-gcs/banner/20240517200210_quantum_computing.jpeg",
          "banner_img_url": "https://storage.googleapis.com/abc-gcs/banner/20240517200214_quantum_computing.jpeg",
          "event_mode": "Virtual",
          "event_type": "side",
          "location": "Online",
          "organizer": [
            {
              "name": "John Doe",
              "profile_link": "https://example.com/johndoe",
              "img_url": "https://example.com/johndoe.jpg"
            }
          ]
        },
        "speaker_details": [
          {
            "name": "Jane Smith",
            "designation": "Expert Speaker",
            "img_url": "https://example.com/janesmith.jpg"
          },
          {
            "name": "Alex Johnson",
            "designation": "Keynote Speaker",
            "img_url": "https://example.com/alexjohnson.jpg"
          }
        ]
      }
    return (
        <Carousel
            className="rounded-xl"
            navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                        <span
                            key={i}
                            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                }`}
                            onClick={() => setActiveIndex(i)}
                        />
                    ))}
                </div>
            )}
        >
            <div className="p-10">
            <Card data={data}/>
            </div>
            <div className="p-10">
            <Card data={data}/>
            </div>
            <div className="p-10">
            <Card data={data}/>
            </div>
        </Carousel>
    )
}


const Speakers = ({speakers}) => {
    return (
        <div className="flex gap-10">
            {speakers?.map((speaker, index) => (
                <div key={index} className="flex items-center gap-4">
                {/* <Avatar src={speaker.img_url} alt="avatar" /> */}
                <Avatar src={"https://docs.material-tailwind.com/img/face-2.jpg"} alt="avatar" />
                <div>
                    <Typography variant="h6">{speaker.name}</Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                    {speaker.designation}
                    </Typography>
                </div>
                </div>
            ))}
        </div>
    )
}

const Sponsors = () => {
    return (
        <div className="flex items-center gap-4">
            <Avatar src="https://docs.material-tailwind.com/img/face-2.jpg" alt="avatar" />
            <div>
                <Typography variant="h6">Tania Andrew</Typography>
                <Typography variant="small" color="gray" className="font-normal">
                    Web Developer
                </Typography>
            </div>
        </div>
    )
}
const Organisers = ({organisers}) => {
    return (
     <div>
         {organisers?.map((organiser, index) => (
             <div className="flex items-center gap-4">
                 {/* <Avatar src="{organiser?.img_url}" alt="avatar" /> */}
                 <Avatar src="https://docs.material-tailwind.com/img/face-2.jpg" alt="avatar" />
                 <div>
                     <Typography variant="h6">{organiser?.name}</Typography>
                     <Typography variant="small" color="gray" className="font-normal">
                         {organiser?.profile_link}
                     </Typography>
                 </div>
             </div>
        ))}
     </div>
    )
}


const GoogleMapLocation = () => {
    return (
        <div className="h-96">
            <LoadScript googleMapsApiKey={"AIzaSyDTg790iZlfKpDScxDQ9FMxSG0oPH8Oe8M"}>
                <GoogleMap
                    mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "8px" }}
                    center={{ lat: 20.242352777, lng: 79.409575 }}
                    zoom={10}
                >
                </GoogleMap>
            </LoadScript>
        </div>
    )
}
export function EventDetailTabs({event}) {
    const [activeTab, setActiveTab] = React.useState("Event Locations");
    const data = [
        {
            label: "Event Details",
            value: "Event Details",
            desc: <EventDetails description={event?.event_details?.description} />
        },
        {
            label: "Sub Events",
            value: "Sub Events",
            desc: <SubEvents subEvents={event?.event_details?.side_event}/>,
        },
        {
            label: "Speakers",
            value: "Speakers",
            desc: <Speakers speakers={event?.speaker_details}/>
        },
        {
            label: "Sponsors",
            value: "Sponsors",
            desc: <Sponsors sponsors={event?.event_details?.sponsors}  />
        },
        {
            label: "Organisers",
            value: "Organisers",
            desc: <Organisers organisers={event?.event_details?.organizer} />,
        },
        {
            label: "Event Locations",
            value: "Event Locations",
            desc: <GoogleMapLocation />,
        },
    ];

    const isSmallerScreen = window.innerWidth <= 768;

    // If the screen is smaller, only render the "Event Details" tab
    if (isSmallerScreen) {
        return (
            <div className="max-w-lg mx-auto">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none bg-transparent p-0"
                        indicatorProps={{
                            className: "bg-transparent border-b-2 border-activeTab shadow-none rounded-none",
                        }}
                    >
                        <Tab
                            value="Event Details"
                            onClick={() => setActiveTab("Event Details")}
                            className={activeTab === "Event Details" ? "text-activeTab" : "text-blue-gray-50"}
                        >
                            Event Details
                        </Tab>
                    </TabsHeader>
                    <TabsBody>
                        <TabPanel value="Event Details">
                            <EventDetails />
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </div>
        );
    }
    
    return (
        <Tabs value={activeTab}>
            <TabsHeader
                className="rounded-none  bg-transparent p-0"
                indicatorProps={{
                    className:
                        "bg-transparent border-b-2  border-activeTab shadow-none rounded-none",
                }}
            >
                {data.map(({ label, value }) => (
                    <Tab
                        key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={activeTab === value ? "text-activeTab" : "text-blue-gray-50"}
                    >
                        {label}
                    </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                {data.map(({ value, desc }) => (
                    <TabPanel key={value} value={value}>
                        {desc}
                    </TabPanel>
                ))}
            </TabsBody>
        </Tabs>
    );
}