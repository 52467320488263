import React, { useState, useEffect } from "react";
import "./BasicInfo.css";

const BasicInfo = ({ formData, setFormData }) => {
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "eventTitle":
        if (!value) {
          error = "Event title is required";
        }
        break;
      case "eventURL":
        if (!value) {
          error = "Event URL is required";
        }
        break;
      case "startDate":
      case "endDate":
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!value.match(datePattern)) {
          error = "Date must be in YYYY-MM-DD format";
        }
        break;
      case "startTime":
      case "endTime":
        const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!value.match(timePattern)) {
          error = "Time must be in HH:MM format (24-hour)";
        }
        break;
      case "websiteURL":
      case "facebookURL":
      case "twitterURL":
      case "linkedinURL":
      case "discordURL":
      case "telegramURL":
        if (!value.match(/^https:\/\/\S+/)) {
          error = 'URL must start with "https://"';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    validateInput(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateInput(name, value);
  };

  useEffect(() => {
    const checkErrors = Object.values(errors).some((error) => error !== "");
    setHasErrors(checkErrors);
  }, [errors]);

  return (
    <form>
      <div className="basic-form flex flex-col py-5 mt-5 w-full rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:max-w-full">
        <div className="flex flex-col px-9 w-full max-md:px-5 max-md:max-w-full">
          <label
            htmlFor="eventTitle"
            className="text-base font-semibold text-justify text-neutral-300 max-md:max-w-full"
          >
            Event Title
          </label>
          <input
            type="text"
            id="eventTitle"
            name="eventTitle"
            value={formData.eventTitle}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Enter Event Name"
            required
            className={`bg-transparent h-[46px] justify-left items-start px-6 py-5 mt-4 text-sm text-left text-white rounded-xl border border-solid max-md:px-5 max-md:max-w-full ${
              errors.eventTitle
                ? "border-red-500"
                : "border-[#727C8A30] bg-zinc-800"
            }`}
          />
          {errors.eventTitle && (
            <span className="text-red-500 text-xs mt-1">
              {errors.eventTitle}
            </span>
          )}

          <label
            htmlFor="eventURL"
            className="mt-6 text-base font-semibold text-justify text-neutral-300 max-md:max-w-full"
          >
            Event URL
          </label>
          <div className="flex">
            <span className="inline-flex px-7 py-4 mt-4 h-[46px] items-center px-3 text-sm bg-[#282C31] border rounded-e-0 border-[#727C8A30] border-e-0 rounded-s-xl dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              https://abc.events/
            </span>
            <input
              name="eventURL"
              type="text"
              id="eventURL"
              value={formData.eventURL}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={`rounded-none px-7 py-4 mt-4 bg-transparent h-[46px] rounded-e-xl border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-solid p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.eventURL ? "border-red-500" : "border-[#727C8A30]"
              }`}
              placeholder=""
            />
          </div>
          {errors.eventURL && (
            <span className="text-red-500 text-xs mt-1">{errors.eventURL}</span>
          )}

          <label
            htmlFor="timeZone"
            className="mt-6 text-base font-semibold text-neutral-300 max-md:max-w-full"
          >
            Time Zone*
          </label>
          <select
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            id="timezones"
            name="timezone"
            className={`inpt-field w-1/2 appearance-none outline-neutral-700 flex bg-transparent gap-5 self-start px-5 py-3.5 mt-4 text-base rounded-xl border border-solid bg-zinc-800 text-neutral-300 ${
              errors.timezone ? "border-red-500" : "border-[#727C8A30]"
            }`}
            required
          >
            <option value="America/New_York">EST - GMT-5 (New York)</option>
            <option value="America/Los_Angeles">
              PST - GMT-8 (Los Angeles)
            </option>
            <option value="Europe/London">GMT - GMT+0 (London)</option>
            <option value="Europe/Paris">CET - GMT+1 (Paris)</option>
            <option value="Asia/Tokyo">JST - GMT+9 (Tokyo)</option>
            <option value="Australia/Sydney">AEDT - GMT+11 (Sydney)</option>
            <option value="Canada/Mountain">MST - GMT-7 (Canada)</option>
            <option value="Canada/Central">CST - GMT-6 (Canada)</option>
            <option value="Canada/Eastern">EST - GMT-5 (Canada)</option>
            <option value="Europe/Berlin">CET - GMT+1 (Berlin)</option>
            <option value="Asia/Dubai">GST - GMT+4 (Dubai)</option>
            <option value="Asia/Singapore">SGT - GMT+8 (Singapore)</option>
          </select>
          {errors.timezone && (
            <span className="text-red-500 text-xs mt-1">{errors.timezone}</span>
          )}

          <div className="mt-8 max-md:max-w-full">
            <div className="date-thing flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="time-labels flex flex-col grow font-white text-base font-semibold text-neutral-300 max-md:mt-10">
                  <label htmlFor="startDate">Start Date (DD-MM-YYYY)</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="12/04/2024"
                    className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                      errors.startDate
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.startDate && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.startDate}
                    </span>
                  )}

                  <label htmlFor="endDate" className="mt-6">
                    End Date (DD-MM-YYYY)
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="12/04/2024"
                    className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                      errors.endDate
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.endDate && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.endDate}
                    </span>
                  )}
                </div>
              </div>
              <div className="time-thingy flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="time-labels flex flex-col grow text-base font-semibold text-neutral-300 max-md:mt-10">
                  <label htmlFor="startTime">Start Time (HH:MM) - 24 Hrs</label>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="22:00"
                    className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg dark:color-white font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                      errors.startTime
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.startTime && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.startTime}
                    </span>
                  )}

                  <label htmlFor="endTime" className="mt-6">
                    End Time (HH:MM) - 24 Hrs
                  </label>
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="22:00"
                    className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                      errors.endTime
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.endTime && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.endTime}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="h-px my-8 bg-[#282C31] border-0" />

        <div className="flex flex-col px-9 w-full text-sm max-md:px-5 max-md:max-w-full">
          <label className="text-lg font-semibold text-neutral-300 max-md:max-w-full">
            Social Media Links*
          </label>
          <div className="social-link flex gap-5 mt-5 w-full max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-black dark:text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 12H21M3 12C3 16.9706 7.02944 21 12 21M3 12C3 7.02944 7.02944 3 12 3M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M12 21C4.75561 13.08 8.98151 5.7 12 3M12 21C19.2444 13.08 15.0185 5.7 12 3"
                    stroke="#000000"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                name="websiteURL"
                value={formData.websiteURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.abc_events.com/"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.websiteURL ? "border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <input
                type="text"
                name="facebookURL"
                value={formData.facebookURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.facebook.com/abc_event"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.facebookURL ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
          <div className="social-link flex gap-5 mt-5 w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
                </svg>
              </span>
              <input
                type="text"
                name="twitterURL"
                value={formData.twitterURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.twitter.com/abc_events"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.twitterURL ? "border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                    clipRule="evenodd"
                  />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </span>
              <input
                type="text"
                name="linkedinURL"
                value={formData.linkedinURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.linkedin.com/abc_events"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.linkedinURL ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
          <div className="social-link flex gap-5 mt-5 w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                </svg>
              </span>
              <input
                type="text"
                name="discordURL"
                value={formData.discordURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.discord.com/abc_events"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.discordURL ? "border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-1 flex-auto rounded-xl border border-[#727C8A30] border-solid bg-zinc-800">
              <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M29.919 6.163l-4.225 19.925c-0.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-0.344 0.344-0.631 0.631-1.294 0.631l0.463-6.556 11.931-10.781c0.519-0.462-0.113-0.719-0.806-0.256l-14.75 9.288-6.35-1.988c-1.381-0.431-1.406-1.381 0.288-2.044l24.837-9.569c1.15-0.431 2.156 0.256 1.781 2.013z" />
                </svg>
              </span>
              <input
                type="text"
                name="telegramURL"
                value={formData.telegramURL}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="https://www.telegram.com/abc_events"
                className={`rounded-none bg-transparent rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.telegramURL ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BasicInfo;
