import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
} from "@material-tailwind/react";

const ProfileSkills = () => {
  return (
    <Card className="mt-6 w-[88vw] md:w-3/4 lg:w-[40%] bg-profile border-profile bg-opacity-40 p-4">
      <CardBody>
        <Typography variant="h5" color="white" className="mb-4">
          <span style={{ color: "#C568FF" }}>Intrested Skills</span>
        </Typography>
        <div className="flex flex-wrap gap-4">
          <Chip
            variant="gradient"
            value="UI Skills"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="Development"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="Crypto"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="NFT"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="Product Designer"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="Visual Designer"
            className="rounded-full text-white"
          />
          <Chip
            variant="gradient"
            value="Design System"
            className="rounded-full text-white"
          />
        </div>
      </CardBody>
      <CardFooter className="pt-0"></CardFooter>
    </Card>
  );
};

export default ProfileSkills;
