import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../features/Login/UserSlice";
import EventReducer from "../features/Events/EventSlice"

const store = configureStore({
    reducer: {
        user: UserReducer,
        events: EventReducer
    }
})

export default store