import React from 'react'
import EventButtons from '../../Components/Home/EventButtons'
import HomeContainer from '../../container/HomeContainer.jsx/HomeContainer'

const Home = () => {
  return (
    <main>
      <div className='max-w-[80rem] mx-auto'>
        <EventButtons />
        <HomeContainer type={"all"}/>
      </div>
    </main>
  )
}

export default Home