import React, { useEffect, useState } from 'react';
import wallet from '../../Assets/Login/wallet-2.svg';
import { useAccount } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const TicketCheckoutForm = ({ setForm }) => {
    const { isConnected, address } = useAccount();
    const { openConnectModal } = useConnectModal();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        walletAddress: address
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(()=>{
        setFormData({ ...formData, ["walletAddress"]: address });
    },[address])

    useEffect(()=>{
        setForm({
            firstName: formData.firstName,
            lastName: formData.lastName,
            emaiId: formData.email,
            contactNumber: formData.contactNumber,
            walletAddress: formData.walletAddress,
        })  
    },[formData])

const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
};

const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
        case 'firstName':
            if (!value) {
                newErrors.firstName = 'First name is required';
            } else {
                delete newErrors.firstName;
            }
            break;
        case 'lastName':
            if (!value) {
                newErrors.lastName = 'Last name is required';
            } else {
                delete newErrors.lastName;
            }
            break;
        case 'email':
            if (!value) {
                newErrors.email = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                newErrors.email = 'Email is invalid';
            } else {
                delete newErrors.email;
            }
            break;
        case 'contactNumber':
            if (!value) {
                newErrors.contactNumber = 'Contact number is required';
            } else {
                delete newErrors.contactNumber;
            }
            break;
        case 'walletAddress':
            if (!value && !isConnected) {
                newErrors.walletAddress = 'Wallet address is required';
            } else if (!/^0x[a-fA-F0-9]{40}$/.test(value) && !isConnected) {
                newErrors.walletAddress = 'Wallet address is invalid';
            } else {
                delete newErrors.walletAddress;
            }
            break;
        default:
            break;
    }

    setErrors(newErrors);
};

const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
        // Submit form data
        console.log('Form submitted:', formData);
    }
};

const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
        validateField(key, formData[key]);
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

return (
    <form onSubmit={handleSubmit}>
        <div className='flex flex-col border-[1px] border-[#636C77] bg-[#282C31] bg-opacity-30 rounded-[16px]'>
            <div className='text-2xl font-semibold text-[#EBECEE] font-gilroy pl-12 mt-4 pb-4 border-b-[1px] border-[#636C77]'>
                Fill the Details
            </div>

            <div className='flex flex-col gap-8 pt-8 pb-8 justify-center items-center border-b-[1px] border-[#636C77]'>
                <div className='flex flex-col items-center lg:flex-row lg:justify-center gap-8 w-full px-16'>
                    <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#808B9A]'>First Name</p>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.firstName ? 'border-red-500' : 'border-[#727C8A]'}`}
                        />
                        {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#808B9A]'>Last Name</p>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.lastName ? 'border-red-500' : 'border-[#727C8A]'}`}
                        />
                        {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                    </div>
                </div>
                <div className='flex flex-col items-center lg:flex-row lg:justify-center gap-8 w-full px-16'>
                    <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#808B9A]'>Email Address</p>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.email ? 'border-red-500' : 'border-[#727C8A]'}`}
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                    </div>

                    <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#808B9A]'>Contact Number</p>
                        <input
                            type="text"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[2.875rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.contactNumber ? 'border-red-500' : 'border-[#727C8A]'}`}
                        />
                        {errors.contactNumber && <p className="text-red-500 text-sm">{errors.contactNumber}</p>}
                    </div>
                </div>
            </div>

            <div className='flex flex-col lg:flex-row justify-center items-center px-16 lg:gap-8 pt-6 pb-12 w-full'>
                <div className='flex flex-col gap-2 w-full lg:w-[70%]'>
                    {isConnected ? (
                        <>
                            <p className='text-[#808B9A]'>Wallet Already Connected</p>
                            <input
                                type="text"
                                name="walletAddress"
                                value={address}
                                disabled
                                placeholder="address"
                                className={`placeholder-[#808B9A] bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[3rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.walletAddress ? 'border-red-500' : 'border-[#727C8A]'}`}
                            />
                        </>
                    ) : (
                        <>
                            <p className='text-[#808B9A]'>Connect Your Receiver Wallet</p>
                            <input
                                type="text"
                                name="walletAddress"
                                value={formData.walletAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter wallet Address"
                                className={`placeholder-[#808B9A] bg-[#282C31] bg-opacity-40 border-[1px] rounded-[12px] w-full h-[3rem] focus:ring-0 focus:outline-none text-[#EBECEE] pl-4 ${errors.walletAddress ? 'border-red-500' : 'border-[#727C8A]'}`}
                            />
                        </>
                    )}
                    {errors.walletAddress && <p className="text-red-500 text-sm">{errors.walletAddress}</p>}
                </div>
                {!isConnected && (
                    <>
                        <p className='mt-6 text-white'>or</p>
                        <div className='pt-8 lg:pt-0 w-full lg:w-[30%] lg:self-end flex justify-end'>
                            <button type="button" onClick={openConnectModal} className="relative rounded-[12px] text-[#C6C6C6] w-[12.75rem] h-[3rem] bg-gradient-to-r from-[#282C31] to-[#1E1E1E] flex items-center justify-center">
                                <img src={wallet} alt="Wallet" className="absolute left-0 h-[1.5rem] ml-4" />
                                <p className="ml-6">Connect to Wallet</p>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    </form>
);
};

export default TicketCheckoutForm;
