import React from "react";
import "./DashBar.css";
import arrow from "../../Assets/SVG/arrow.svg";

const SwitchButtons = () => {
  return (
    <>
      <div className="dashbar flex px-6 flex-row justify-between items-center bg-[#282C31] w-[59.375rem] bg-opacity-30 h-[4.125rem] border-[#636C77] border-[1px] rounded-[18px]">
        <div
          className="font-gilroy font-bold"
          style={{
            background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          Dashboard
        </div>

        <div className="links flex flex-row justify-between items-center px-2 w-[26rem] h-[2.875rem] bg-[#282C31] bg-opacity-40 rounded-[14px]">
          <div className="text-[#BDC2C9]">
            https://abc.events/5irerward-eventsds....
          </div>
          <div>
            <button className="bg-[#B700FF] px-4 py-1 text-white rounded-[14px]">
              Copy URL
            </button>
          </div>
        </div>
        <div className="arrow">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </>
  );
};

export default SwitchButtons;
