import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
import NFT from "../../Assets/Images/NFT Image.png"

  export function EventNftMintCard({nft_image}) {
    return (
      <Card className=" border-[#282C31] border-[1px] bg-opacity-30 mb-4 rounded-xl p-4 bg-[#282C31]">
        <CardHeader shadow={false} floated={false} className="border-0 bg-transparent">
          <img
            src={nft_image?nft_image:NFT}
            alt="card"
            className="h-full w-full object-cover"
          />
        </CardHeader>
        <CardFooter className="pt-0">
          <Button
            // ripple={false}
            fullWidth={true}
            className="bg-gradient-to-r from-purple-600 via-purple-700 to-purple-800 mt-10 normal-case text-white"
            style={{
              background: 'linear-gradient(90deg, #6042FF 0%, rgba(96, 66, 255, 0.7) 100%)',
              boxShadow: '0px 4px 2px 0px #00000040 inset'
            }}
          >
            Connect with members
          </Button>
        </CardFooter>
      </Card>
    );
  }