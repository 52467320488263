import React from 'react';
import search from '../../Assets/Images/search.svg';

const SwitchButtons = () => {
    return (
        <>
            <div className="flex px-6 flex-row justify-between items-center bg-[#282C31] w-[59.375rem] bg-opacity-30 h-[3rem] border-[#636C77] border-[1px] rounded-[12px]">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search Name, E Mail... Weight"
                        className="bg-[#282C31] bg-opacity-40 text-[#D5D8DC] py-2 pr-8 pl-10 font-normal w-[16.5rem] rounded-[10px] outline-none focus:bg-opacity-50"
                        style={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', textAlign: 'center' }}
                    />
                    <img src={search} alt="Search" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                </div>

                <p>
                    <button className="px-8 h-[2rem] text-[#FFFFFF] rounded-[10px] shadow-md" style={{ background: 'linear-gradient(90deg, #6042FF 0%, rgba(96, 66, 255, 0.7) 100%)', boxShadow: '0px 4px 2px 0px #00000040 inset' }}>
                        <span className="">Expert CSV</span>
                    </button>
                </p>
            </div>
        </>
    );
}

export default SwitchButtons;

