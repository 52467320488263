import React, { useState } from "react";
import Logo from "../../Assets/SVG/Logo.svg";
import bell from "../../Assets/Images/bell.svg";
import search from "../../Assets/Images/search.svg";
import dropdown from "../../Assets/Images/dropdown.svg";
import profile from "../../Assets/Images/profile.svg";
import dash from "../../Assets/Images/dash.svg";
import logout from "../../Assets/Images/logout.svg";
import profile1 from "../../Assets/Images/profile1.svg";
import settings1 from "../../Assets/Images/settings1.svg";
import wallet from "../../Assets/Images/wallet.svg";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from 'wagmi';
import { Profile } from "../Input/Profile";


export function NavBar({setProfileMenuStatus}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate()
  const { isConnected } = useAccount();
  const {disconnect} = useDisconnect();


  const handleLogout = () => {
      if(isConnected){
        disconnect()
      }
      localStorage.removeItem("Authorization")
      window.location.reload()
    
  }
 
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="sticky top-0 z-100 max-w-full px-4 lg:px-16 pt-5 pb-4 bg-transparent navbar">
      <div className="relative mx-auto flex items-center justify-between text-white">
        {/* Logo */}
        <img onClick={() => { navigate("./") }} src={Logo} alt="logo" className="h-5 sm:h-10" />

        {/* Right side content */}
        <div className="ml-auto flex items-center">
          {/* Search Bar */}
          <div className="relative sm:block">
            <input
              type="text"
              placeholder="Search Events..."
              className="px-3 py-2 pl-10 w-[6rem] md:w-[8rem] lg:w-[15rem] bg-[#74737333] rounded-[12px] mr-4"
              style={{
                backgroundImage: `url(${search})`,
                backgroundSize: "20px",
                backgroundPosition: "10px center",
                backgroundRepeat: "no-repeat"
              }}
            />
          </div>

          {/* Bell Icon */}
          <Profile setProfileMenuStatus={setProfileMenuStatus}/>
          <img src={bell} alt="bell" className="hidden sm:block ml-4 mr-4 bg-[#7473734D] p-2 rounded-3xl" />

          {/* Profile Image */}

          {/* Dropdown */}
        </div>
      </div>
    </div>

  );
}
