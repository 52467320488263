import React, { useEffect, useState } from "react";
import ProfileContainer from "../../container/ProfileContainer/ProfileContainer";
import { Button } from "@material-tailwind/react";
import EditProfileContainer from "../../container/ProfileContainer/EditProfileContainer";
import { useParams } from "react-router-dom";
import { fetchUser } from "../../features/Login/UserSlice";
import { useDispatch } from "react-redux";

const ProfileInfo = () => {
  const { _id } = useParams()
  const [profileInfoData, setProfileInfoData]= useState();
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchUser(_id)).then((result) => {
        console.log(result,"fetchUser")
        setProfileInfoData(result?.payload?.user)
    })
    console.log(_id,"id")
}, [])


  return (
    <main>
      <div className="HomescrollContainer" style={{ height: "78vh" }}>
       {profileInfoData?  <ProfileContainer profileInfoData={profileInfoData} /> :  <ProfileContainer />}
      </div>
    </main>
  );
};

export default ProfileInfo;
