import React from 'react'
import Home from './pages/Home/Home'
import Rewards from './pages/rewards/Rewards'
import EventDashboard from './pages/eventDashboard/EventDashboard'
import { Route, Routes } from 'react-router-dom'
import Profile from './pages/profile/Profile'
import ProfileInfo from './pages/profileInfo/ProfileInfo'
import Events from './pages/events/Events'
import EventInfo from './pages/eventInfo/EventInfo'
import Checkin from './pages/checkin/Checkin'
import Inprogress from './Components/InProgress/Inprogress'
import MyEvents from './pages/myEvents/MyEvents'

const Services = () => {

    const data=[
        {
            path:"/",
            element: <Home/>
            
        },
        {
            path:"/event/*",
            element: <Events/>

        },
        {
            path: "/events",
            element: <EventDashboard/>
        },
        {
            path:`/eventInfo/:_id`,
            element: <EventInfo/>
        },
        {
            path:`/profileInfo/:_id`,
            element: <ProfileInfo/>
        },
        {
            path:"/profile",
            element: <Profile/>
        },
        {
            path: "/checkins/*",
            element: <Checkin />
        },
        {
            path:"/my-events",
            element: <MyEvents/>
        },
        {
            path: "/rewards",
            element: <Rewards/>
        },
    ]
  return (
    <Routes>
        {data.map((item,key)=>{
            return <Route path={item.path} element={item.element} key={key}/>
        })}
    </Routes>
  )
}

export default Services