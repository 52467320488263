import React from "react";
import people from "../../Assets/Images/people.svg";
import "./horizontalBtns.css";

const HorizontalBtns = ({ count, description }) => {
  return (
    <>
      <div className="hzbutton flex flex-row border-[1px] justify-between p-[0.6rem] bg-opacity-30 bg-[#282C31] border-[#636C77] w-[18.125rem] h-[6.563rem] rounded-[20px]">
        <div className="flex flex-col gap-2 justify-center ml-2">
          <p className="text-3xl font-gilroy font-semibold text-[#D5D8DC]">
            {count}
          </p>
          <p className="text-base text-[#727C8A] font-normal">{description}</p>
        </div>

        <div className="h-full flex justify-center rounded-xl items-center p-6 bg-[#282C31] bg-opacity-40">
          <img
            src={people}
            alt="people"
            className="max-h-full max-w-full opacity-40"
          />
        </div>
      </div>
    </>
  );
};

export default HorizontalBtns;
