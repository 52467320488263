import React, {useState} from 'react'
import Services from '../../Routes'
import { NavBar } from '../../Components/Navbar/Navbar'
const Dashboard = () => {
  const [profileMenuStatus, setProfileMenuStatus] = useState(false);

  return (
    <div className='home-main' style={{opacity: profileMenuStatus ? 0.9 : 1}}>
      <NavBar setProfileMenuStatus={setProfileMenuStatus}/>
      <Services/>
    </div>
  )
}

export default Dashboard