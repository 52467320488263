import React from 'react';
import Gold from '../../Assets/rewards/goldstar.svg';
import Silver from '../../Assets/rewards/silverstar.svg';
import Right from '../../Assets/rewards/arrow-right.svg';
import BGP from '../../Assets/rewards/bgviolet.svg';
import BGB from '../../Assets/rewards/bgblue.svg';

const RewardListing = ({ xp, type }) => {
    const isGold = type === 'event_registration';
    const background = isGold ? BGP : BGB;
    const starImage = isGold ? Gold : Silver;

    return (
        <div className='w-full flex justify-between items-center border-[1px] border-[#282C31] rounded-[12px] px-12 py-2'
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className='flex flex-row justify-center items-center gap-6'>
                <img src={starImage} alt={isGold ? 'Gold Star' : 'Silver Star'} className='h-auto' />
                <p className='text-[#D5D8DC] mt-1 font-medium text-lg'>{type}</p>
            </div>

            <div className='flex flex-row items-center gap-12'>
                <div className="flex items-center gap-2">
                    <p className="text-2xl font-semibold font-gilroy" style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>{xp}</p>
                    <span className="text-md font-semibold" style={{ background: "linear-gradient(180deg, #E900BA 0%, #830069 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>XP</span>
                </div>

                <div className="flex items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full" style={{ background: "linear-gradient(238.82deg, rgba(252, 252, 255, 0.5) -10.32%, rgba(96, 66, 255, 0.5) 132.38%)" }}>
                    <img src={Right} alt='Arrow Right' className='w-[1.375rem] h-[1.375rem]' />
                </div>
            </div>
        </div>
    );
};

export default RewardListing;
