import React, { useState } from "react";
import ProfileContainer from "../../container/ProfileContainer/ProfileContainer";
import { Button } from "@material-tailwind/react";
import EditProfileContainer from "../../container/ProfileContainer/EditProfileContainer";

const Profile = () => {
  const [edit, setEdit] = useState(false);

  return (
    <main>
      <div className="w-10/12 flex justify-between m-auto mt-5">
        <span className="text-white font-extrabold lg:text-3xl font-gilroy text-2xl lg:font-bold">
          User Profile
        </span>
        <div>
          <Button
            onClick={() => setEdit(!edit)}
            className="sm:text-sm sm:px-4 sm:py-2"
          >
            Edit Profile
          </Button>
        </div>
      </div>
      <div className="" style={{}}>
        {!edit ? <ProfileContainer /> : <EditProfileContainer setEdit={setEdit}/>}
      </div>
    </main>
  );
};

export default Profile;
