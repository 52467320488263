import { Button } from "@material-tailwind/react";
import React,{useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchDraftsEvents } from "../../features/Events/EventSlice";
import Card from "../../Components/Card/Card";

const EventOverview = () => {

  const dispatch = useDispatch()
  const [data,setData] = useState([])
  useEffect(()=>{
    dispatch(fetchDraftsEvents()).then((result)=>{
      console.log(result)
      if(result?.payload)
        {
          setData(result?.payload)
        }
    })
  },[])
  const navigate = useNavigate();
  return (
    <div className="w-full max-w-[80rem] m-auto px-4">
      <div className="flex flex-col md:flex-row justify-between mt-8 mb-8">
        <span className="text-white text-2xl mb-4 md:mb-0">
          Dashboard Overview
        </span>
        <Button
          onClick={() => navigate("/event/eventInfo")}
          className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 normal-case"
        >
          Create Event
        </Button>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="flex justify-between border-[1px] border-[#636C77] rounded-xl p-6 md:p-10 bg-[#74737333] flex-1">
          <div className="flex flex-col">
            <span className="text-white text-2xl md:text-3xl">12.5K</span>
            <span className="text-white">Total No of users</span>
          </div>
          <div></div>
        </div>
        <div className="flex justify-between border-[1px] border-[#636C77] rounded-xl p-6 md:p-10 bg-[#74737333] flex-1">
          <div className="flex flex-col">
            <span className="text-white text-2xl md:text-3xl">{data?.filter((value)=>value?.is_cancelled!==true)?.length}</span>
            <span className="text-white">Total No of events</span>
          </div>
          <div></div>
        </div>
        <div className="flex justify-between border-[1px] border-[#636C77] rounded-xl p-6 md:p-10 bg-[#74737333] flex-1">
          <div className="flex flex-col">
            <span className="text-white text-2xl md:text-3xl">10</span>
            <span className="text-white">Total No of Campaigns</span>
          </div>
          <div></div>
        </div>
      </div>
      {data?.map((value,index)=>{
        if(value?.is_cancelled)
          {
            return ("")
          }
        return(
          <Card data={value} type={"manage"} />
        )
      })}
    </div>
  );
};

export default EventOverview;
