import React, { useEffect, useState } from "react";
import ProfileInfoCard from "../../Components/Card/ProfileInfoCard";
import ProfileLinkCard from "../../Components/Card/ProfileLinkCard";
import ProfileSkills from "../../Components/Card/ProfileSkills";
import ProfileRecentEvents from "../../Components/Card/ProfileRecentEvents";
import Banner from "../../Components/Profile/Banner";
import { profile } from "../../features/Login/UserSlice";
import { useDispatch } from "react-redux";

const ProfileContainer = ({profileInfoData}) => {
    const [profileData, setProfileData] = useState("");
    const dispatch = useDispatch();

    const getProfile = async () => {
        const response = await dispatch(profile());
        if (profile.fulfilled.match(response)) {
            console.log(response.payload.user,"profile data")
            setProfileData(response.payload.user);
        } else {
            const error = response.error;
            console.error("Profile request failed:", error);
            throw new Error("Profile request failed");
        }
    };

    console.log("profileData", profileData)

    useEffect(() => {
        if(profileInfoData){
            setProfileData(profileInfoData)
            console.log("setting profileInfoData")
        }
        else{
            getProfile();
            console.log("fetchin profile")

        }
    }, [profileInfoData]);

    return (
        <div className="w-full m-auto flex flex-col justify-center items-center">
            <Banner profileData={profileData} />
            <ProfileInfoCard profileData={profileData}/>
            <ProfileLinkCard profileData={profileData}/>
            <ProfileSkills />
            <ProfileRecentEvents />
        </div>
    );
};

export default ProfileContainer;
