import React from "react";
import HorizontalBtns from "../../Components/CreateEvents/horizontalBtns";
import Scanner from "../../Components/CreateEvents/Scanner";
import DashBar from "../../Components/CreateEvents/DashBar";
import SwitchButtons from "../../Components/CreateEvents/SwitchButton";
import SearchBar from "../../Components/CreateEvents/SearchBar";
import Pagination from "../../Components/CreateEvents/Pagination";
import EventTable from "../../Components/CreateEvents/EventTable";
import "./EventDashboard.css";

const EventDashboard = () => {
  return (
    <div className="main-div flex flex-col gap-6">
      <div className="top-div flex flex-col gap-8 pb-4 border-b-[1px] border-[#282C31]">
        <DashBar />
        <div className="flex justify-end">
          <Scanner />
        </div>
      </div>

      <div className="deets1-div flex flex-row gap-10">
        <HorizontalBtns count="650" description="Total No.of.Attendee" />
        <HorizontalBtns count="$12.5k" description="Total Revenue" />
        <HorizontalBtns count="0" description="Total Check-In" />
      </div>

      <div
        className=" pvi-text font-gilroy text-lg font-semibold mt-2 sm:ml-4"
        style={{
          background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        {" "}
        Page Visitor Information{" "}
      </div>

      <div className="visitor-info-div flex flex-row gap-12 border-b-[1px] border-[#282C31] pb-4">
        <div className="visitor-graph w-[38.75rem] h-[20.938rem] bg-[#282C31] bg-opacity-30 rounded-2xl border-1px border-red-700"></div>

        <div className="deets2-div flex flex-col gap-2">
          <HorizontalBtns count="650" description="Total No.of.Attendee" />
          <HorizontalBtns count="$12.5k" description="Total Revenue" />
          <HorizontalBtns count="0" description="Total Check-In" />
        </div>
      </div>

      <div className="table-thing flex flex-col gap-4 mb-8">
        <div className="switch-button flex justify-end">
          <SwitchButtons />
        </div>

        <div className="flex justify-end">
          <SearchBar />
        </div>

        <div className="flex justify-end">
          <EventTable />
        </div>

        <div className="pagination flex justify-end">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default EventDashboard;
