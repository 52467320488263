import React from 'react';
import sms from '../../Assets/Images/CreateEvents/sms.svg';
import book from '../../Assets/Images/CreateEvents/book.svg';
import person from '../../Assets/Images/CreateEvents/person.svg';
import ticket from '../../Assets/Images/CreateEvents/ticket-2.svg';
import setting from '../../Assets/Images/CreateEvents/setting-2.svg';
import layout from '../../Assets/Images/CreateEvents/layout.svg';

const TopBar = () => {
    return (
        <div className="flex pl-2 pr-2 m-auto my-6 flex-row justify-between items-center bg-[#282C31] w-full xl:w-full bg-opacity-30 h-[3.5rem] border-[#282C31] border-[1px] rounded-[16px]">
            <div className='flex flex-row'>
                <p className='text-base font-normal text-[#BDC2C9] flex items-center justify-center w-40 h-10 rounded-[14px] hover:bg-[#282C31] hover:bg-opacity-40 transition-opacity duration-300'>
                    <img src={layout} alt="SMS" className="mr-2" /> Event Details
                </p>
            </div>
            <div>
                <p className='text-base flex font-normal text-[#BDC2C9] mr-4'>
                    <img src={book} alt="Setting" className="mr-2" /> Help
                </p>
            </div>
        </div>
    );
}

export default TopBar;