import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Avatar,
    Typography,
  } from "@material-tailwind/react";
   
import profile1 from "../../Assets/Images/profile1.svg";
import React, { useState } from "react";
import Dash from "../../Assets/Images/dash.svg"
import settings1 from "../../Assets/Images/settings1.svg";
import logout from "../../Assets/Images/logout.svg";
import { useAccount, useDisconnect } from 'wagmi';
import { useNavigate } from "react-router-dom";
 
export function Profile({setProfileMenuStatus}) {

    const { isConnected } = useAccount();
    const {disconnect} = useDisconnect();
    const handleLogout = () => {
        if(isConnected){
          disconnect()
        }
        localStorage.removeItem("Authorization")
        window.location.reload()
      
    }
    const navigate = useNavigate()
    const [openMenuStatus, setOpenMenuStatus] = useState(false);
    const menuClickHandler = (val) => {
      setOpenMenuStatus(val)
      setProfileMenuStatus(val)
    }

    return (
      <Menu open={openMenuStatus} handler={menuClickHandler}>
        <MenuHandler>
          <Avatar
            variant="circular"
            alt="tania andrew"
            className="cursor-pointer"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
          />
        </MenuHandler>
        <MenuList className="border-[#4B0068] bg-custom-gradient text-white">
          <MenuItem onClick={() => { navigate("/my-events") }} className="flex items-center gap-2">
            <img  src={Dash} alt="Dashboard" className="h-10 mr-2" />
            <Typography variant="small" className="font-medium">
            My Events
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => { navigate("./") }} className="flex items-center gap-2">
            <img  src={Dash} alt="Dashboard" className="h-10 mr-2" />
            <Typography variant="small" className="font-medium">
            Dashboard
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => { navigate("./profile") }} className="flex items-center gap-2">
            
            <img src={profile1} alt="Profile" className="h-10 mr-2" />
              <Typography variant="small" className="font-medium">
                My Profile
              </Typography>
            </MenuItem>
            <MenuItem className="flex items-center gap-2">
            <img src={settings1} alt="Settings" className="h-10 mr-2" />
              <Typography variant="small" className="font-medium">
                  Settings
              </Typography>
            </MenuItem>
          <hr className="my-2 border-blue-gray-50" />
          <MenuItem onClick={handleLogout} className="flex items-center gap-2 ">
          <img src={logout} alt="Logout" className="h-10 mr-2" />
            <Typography variant="small" className="font-medium">
              Sign Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }