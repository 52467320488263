import React, { useState } from 'react'
import { Button } from '@material-tailwind/react'
import { cancelEvent } from '../../features/Events/EventSlice';
import { useDispatch } from 'react-redux';
import { Bounce, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const CancelEvent = () => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const navigate= useNavigate()
    const handleCancelation = () => {
        console.log("cancellation");
        dispatch(cancelEvent()).then(async (result) => {
            toast("🦄 Event Canceled Successfully!!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
              }); 
            navigate("/event/Overview")
            console.log(result,"cancelation result")
        });
        
    };
    return (
        <>
           {showModal && (
                <div className='fixed z-10 inset-0 overflow-y-auto flex items-center justify-center bg-[#1E1E1E80]'>
                    <div className='border-[1px] flex flex-col bg-[#0F0C0C] border-[#282C31] h-[13.125rem] w-[24rem] rounded-[20px]'>
                        <div className='flex items-center justify-between pl-4 pr-2 pb-2 border-b-[1px] border-[#282C31] pt-2'>
                            <div className='font-gilroy text-[#D5D8DC] font-semibold'>
                            Cancel Event ?
                            </div>
                            <button onClick={handleCloseModal} className='rounded-full w-[30px] h-[30px] bg-[#721924] bg-opacity-40 flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#C6C6C6]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                        </div>

                        <div className='flex flex-col justify-center gap-6 items-center mt-6'>
                            <div className='flex flex-col justify-center items-center'>
                                <div className='text-[#EBECEE] px-4'>
                                This will delete this event premanently. You cannot Undo this action.
                                </div>
                            </div>

                            <div className='flex flex-row gap-4 mr-6 justify-end w-full'>
                                <button onClick={handleCloseModal} className='rounded-[10px] font-gilroy text-[#A2A9B3] font-medium w-[8.125rem] h-[2.375rem] bg-[#282C31]'>
                                    Close
                                </button>

                                <button onClick={handleCancelation} className='rounded-[10px] font-gilroy text-white font-medium w-[8.125rem] h-[2.375rem] bg-[#C62B3E] '>
                                    Cancel Event
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        <div className="flex text-white flex-col gap-4 border-[1px] border-[#282C31] w-full mt-8 mb-4 rounded-xl p-4 bg-[#282C31] bg-opacity-30">
            <h1 className='font-bold text-xl'> Event Cancellation</h1>
            <p className='w-full md:w-3/4 font-light'>
            Cancel and permanently remove this event. This action is irreversible. Registered guests will be notified of the cancellation.
            </p>
            <Button className='w-[12.75rem] normal-case' style={{
                        background: '#C62B3E',
                        boxShadow: '0px 4px 2px 0px #00000040 inset'
                    }}
                    onClick={()=>setShowModal(true)}
                    >
                        Cancel Event
            </Button>
        </div>
        </>
    )
}

export default CancelEvent