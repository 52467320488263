import React from 'react';
import camera from '../../Assets/Images/camera.svg';

const ScanQr = () => {
    return (
        <>
            <div className="w-[10.75rem] h-[2.625rem] flex gap-4 justify-center items-center rounded-[12px] bg-[#282C31]">
                <img src={camera} alt='camera' />
                <div className='text-[#BDC2C9] font-medium text-lg'>Scan QR</div>
            </div>
        </>
    );
}

export default ScanQr;
