import React, { useEffect, useState } from 'react';
import RewardBanner from '../../Components/Rewards/RewardBanner';
import RewardListing from '../../Components/Rewards/RewardListing';
import { useDispatch } from 'react-redux';
import { fetchReferralHistory } from '../../features/Rewards/RewardsSlice';

const Rewards = () => {

    const dispatch = useDispatch();
    const [referralHistory,setReferralHistory]=useState();
    const [totalPoints,setTotalPoints]=useState(0);

    useEffect(() => {
        dispatch(fetchReferralHistory()).then((res)=>{
            console.log(res.payload.message,"fetchReferralHistory")
            const result = res.payload.message
            setReferralHistory(result.referral_history)
            setTotalPoints(result.total_referral_points)
        });
    }, [dispatch]);


    const rewardData = [
        { type: 'gold', xp: 100 },
        { type: 'gold', xp: 100 },
        { type: 'gold', xp: 100 },
        { type: 'gold', xp: 100 },
        { type: 'gold', xp: 100 },
    ];

    const rewardData2 = [
        { type: 'silver', xp: 100 },
        { type: 'silver', xp: 100 },
        { type: 'silver', xp: 100 },
    ];

    const rewardData3 = [
        { type: 'gold', xp: 100 },
        { type: 'gold', xp: 100 },
    ];

    return (
        <>
            <div className="flex flex-col h-screen w-[80rem] mx-auto gap-4 pt-6">
                <RewardBanner totalPoints={totalPoints} />
                <p className='text-2xl pl-16 border-b-[1px] pb-2 border-[#282C31] font-gilroy' style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>
                    List of Reward Points
                </p>

                <div className='flex flex-col gap-2 pb-4 border-b-[1px] border-[#282C31]'>
                    {referralHistory?.map((reward, index) => (
                        <RewardListing key={index} type={reward.referral_type} xp={reward.points} />
                    ))}
                </div>

                {/* <div className='flex flex-col gap-2 pb-4 border-b-[1px] border-[#282C31]'>
                    {rewardData2.map((reward, index) => (
                        <RewardListing key={index} type={reward.type} xp={reward.xp} />
                    ))}
                </div>

                <div className='flex flex-col gap-2'>
                    {rewardData3.map((reward, index) => (
                        <RewardListing key={index} type={reward.type} xp={reward.xp} />
                    ))}
                </div> */}

            </div>
        </>
    );
}

export default Rewards;
