import React from 'react'
import EventList from '../../Components/CreateEvents/EventList'
import { Route, Routes } from 'react-router-dom'
import EventDashboard from '../EventDashboardContainer/EventDashboard'
import EventInfoForm from '../../Components/form/EventInfoForm'
import Sidebar from '../../Components/Sidebar'
import TicketAndPaymentForm from '../../Components/form/TicketAndPaymentForm'
import CancelEvent from '../../Components/Card/CancelEvent'

const EventFormContainer = () => {
  return (
    <div className="flex w-[80rem] gap-8 my-10 mx-auto">
      <div className="hidden lg:flex sidebar">
        <EventList />
      </div>
      <div className="mainArea w-3/4">
        <Routes>
          <Route path="/eventDashboard" element={<EventDashboard />} />
          <Route path="/eventInfo" element={<EventInfoForm />} />
          <Route path="/TicketAndPayment" element={<TicketAndPaymentForm />} />
          <Route path='/eventSettings' element={<CancelEvent/>} />
        </Routes>
      </div>
    </div>
  );
};

export default EventFormContainer;
