import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
} from "@material-tailwind/react";
import event from "../../Assets/Images/event.png";
import Date from "../../Assets/Images/Date.png";

const ProfileRecentEvents = () => {
  return (
    <Card className="mt-6 w-[88vw] md:w-3/4 lg:w-[40%] bg-profile border-profile bg-opacity-40 p-4">
      <CardBody>
        <Typography variant="h5" color="white" className="mb-2">
          <span style={{ color: "#C568FF" }}>Recent Events</span>
        </Typography>
        <div className="flex justify-center">
          <div className="flex flex-col lg:flex-row gap-8 border-[1px] border-[#636C77] h-auto w-[80rem] mt-8 mb-4 rounded-xl p-4 bg-[#74737333]">
            <div>
              <img src={event} alt="event" />
            </div>
            <div className="flex flex-col flex-grow pr-4">
              {/* Event Name */}
              <div className="text-[#D5D8DC] text-2xl pb-4 border-b-[1px] border-[#636C77]">
                5ireChain Community Rewards
                <br />
                Campaign
              </div>
              <div className="mt-4 text-[#727C8A] font-semibold">
                by Start Insights & Zandhiya
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col lg:flex-row gap-8 border-[1px] border-[#636C77] h-auto w-[80rem] mt-8 mb-4 rounded-xl p-4 bg-[#74737333]">
            <div>
              <img src={event} alt="event" />
            </div>
            <div className="flex flex-col flex-grow pr-4">
              {/* Event Name */}
              <div className="text-[#D5D8DC] text-2xl pb-4 border-b-[1px] border-[#636C77]">
                5ireChain Community Rewards
                <br />
                Campaign
              </div>
              <div className="mt-4 text-[#727C8A] font-semibold">
                by Start Insights & Zandhiya
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter className="pt-0"></CardFooter>
    </Card>
  );
};

export default ProfileRecentEvents;
