import React, { useState } from 'react';
import LoginContainer from '../../container/logincontainer/LoginContainer';
import Top from "../../Assets/SVG/Top Gradient (1).svg";
import Clone from "../../Assets/SVG/Cone_01 2.svg";
import Logo from "../../Assets/SVG/Logo.svg";
import LoginOtpContainer from '../../container/logincontainer/LoginOtpContainer';
import { useSelector } from 'react-redux';

const LoginPage = () => {

  const [login, setLogin] = useState(false);
  const [phone, setPhone] = useState(false);
  const { otp } = useSelector((state) => state.user);

  return (
    <main className="login-main fixed top-0 left-0 w-full h-full">
      <div className='relative w-full h-full overflow-auto'>
        <img src={Top} className="fixed top-0 left-0 w-full h-auto" alt="" />
        <img src={Logo} className='fixed top-4 left-4' alt="Logo" />
        {!otp ?
          <>
            <div className="LoginCard">
              <img src={Clone} className='absolute top-0 left-1/4 clone-image' alt="clone" />
              <LoginContainer setLogin={setLogin} phone={phone} setPhone={setPhone} />
            </div>
          </> :
          <div className="LoginCard">
            <img src={Clone} className='absolute top-0 right-0 clone-image' alt="clone" />
            <LoginOtpContainer />
          </div>
        }
      </div>
    </main>
  );
}

export default LoginPage;
