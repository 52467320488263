import React from "react";
import ProfileForm from "../../Components/form/ProfileForm";
import Banner from "../../Components/Profile/Banner";

const EditProfileContainer = ({setEdit}) => {
  return (
    <div className="w-full m-auto flex flex-col justify-center items-center">
      {/* <Banner /> */}
      <ProfileForm setEdit={setEdit}/>
    </div>
  );
};

export default EditProfileContainer;
