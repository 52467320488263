import React from "react";
import search from "../../Assets/Images/search.svg";
import "./Pagination.css";

const SwitchButtons = () => {
  return (
    <>
      <div className="pagination flex px-6 flex-row justify-between items-center bg-[#282C31] w-[59.375rem] bg-opacity-30 h-[2.313rem] border-[#636C77] border-[1px] rounded-[12px]"></div>
    </>
  );
};

export default SwitchButtons;
