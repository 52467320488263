import { Button } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import Otp from "../../Assets/SVG/Otp.svg"
import { useDispatch, useSelector } from 'react-redux'
import { loginOtp, profile, verifyOtp } from '../../features/Login/UserSlice'


const LoginOtpContainer = () => {
    const [otp, setOtp] = useState("")
    const dispatch = useDispatch()
    const { mobile } = useSelector((state) => state.user)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [resendTimer, setResendTimer] = useState(50);
    const otpChangeHandler = (value) => {
        setError(false)
        setOtp(value)
    }

    const generateOTP = ()=>{
            dispatch(loginOtp({
              "mobile": mobile
            }))
    }

    const resendOTP = () => {
        generateOTP();
        setResendTimer(50);
      };
    
      // Effect to decrement timer every second
      useEffect(() => {
        const timerInterval = setInterval(() => {
          setResendTimer(prevTimer => prevTimer - 1);
        }, 1000);
    
        // Cleanup function to clear interval
        return () => clearInterval(timerInterval);
      }, []);

    const verifyOtpHandler = () => {
        setLoading(true)
        dispatch(verifyOtp({
            "mobile": mobile,
            "otp": otp
        })).then((result) => {
            setLoading(false)
            if (result.payload.token !== undefined) {
                dispatch(profile())
            }
            else {
                setError(true)
                console.error("Incorrect OTP")
            }
        })
    }

    return (
        <div>
            <div className='z-100  relative p-4 flex flex-col gap-6 justify-center'>
                <Button className='px w-20'>
                    <img src={Otp} alt="login" />
                </Button>
                <div>
                    <p className='text-xl text-white font-bold'>Verification Code</p>
                    <p className='text-sm text-white w-1/2 opacity-30'>Please, enter the 6 digit verification code,
                        We send to your mobile {mobile}</p>
                </div>
                <OTPInput value={otp} onChange={otpChangeHandler} renderSeparator={<span className='text-white'>  -  </span>} containerStyle={{ alignItems: "center", justifyContent: "center", gap: "10px", flexWrap:"wrap" }} placeholder='000000' inputStyle={{ width: "52px", height: "52px", background: "transparent", color: "white", border: "1px solid #2E2D2F", borderRadius: "8px", zIndex: 10 }} numInputs={6} renderInput={(props) => <input style={{ width: "2rem" }}  {...props} />} />
                {error ? <span className='text-deep-orange-600'>Incorrect OTP</span> : ""}
                <div className='flex justify-between w-full'>
                    <Button loading={loading} onClick={verifyOtpHandler} className='w-1/3 ' disabled={otp.length!==6}>Proceed</Button>
                    <Button className='w-1/3 ' disabled={resendTimer >= 0} onClick={resendOTP} >Resend</Button>
                </div>
                {resendTimer>=0?<span className='text-deep-orange-600'>Resend otp {resendTimer}sec</span>:""}
                {/* {disabled}<div>
                    <h1>Resend Code Timer</h1>
                    <p>Counter: {counter}</p>
                </div> */}
            </div>
        </div>
    )
}

export default LoginOtpContainer