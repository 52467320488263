import React from 'react';

const SwitchButtons = () => {
    return (
        <>
            <div className="flex flex-row justify-evenly items-center bg-[#282C31] w-[20.625rem] bg-opacity-30 h-[3rem] border-[#636C77] border-[1px] rounded-[16px]">
                <p className='text-base font-semibold text-[#D5D8DC] flex items-center justify-center w-40 h-10 rounded-[14px] hover:bg-[#282C31] hover:bg-opacity-40 transition-opacity duration-300'>
                    Total Sales
                </p>
                <p className='text-base font-semibold text-[#D5D8DC] flex items-center justify-center w-40 h-10 rounded-[14px] hover:bg-[#282C31] hover:bg-opacity-40 transition-opacity duration-300'>
                    Check-In's
                </p>
            </div>
        </>
    );
}

export default SwitchButtons;
