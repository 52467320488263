import React, { useState } from 'react';
import Modal from './Modal'; // Assuming you have a separate Modal component

const Button = ({ type }) => {
    const [showModal, setShowModal] = useState(false);

    const dotColor = type === 'checkin' ? '3EB049' : 'FF3850';
    const buttonText = type === 'checkin' ? 'Checked In' : 'Cancelled';
    const buttonBgColor = type === 'checkin' ? 'bg-[#1C4F21]' : 'bg-[#721924]'; // Using Tailwind CSS syntax

    const handleButtonClick = () => {
        setShowModal(true);
    };

    return (
        <>
            <div className={`flex px-8 flex-row justify-between items-center w-full bg-[#282C31] bg-opacity-20 border-[#282C31] border-[1px] rounded-[12px] h-[2.875rem]`}>
                <div className='text-[#EBECEE]'>
                    zandhiyaa Sans
                </div>

                <div className='text-[#EBECEE]'>
                    sandhiya2020@gmail.com
                </div>

                <div className='text-[#EBECEE]'>
                    26-06-2024 / 15:02 PM
                </div>

                <div className={`flex justify-center items-center ${buttonBgColor} w-[8.75rem] h-[1.563rem] bg-opacity-40 rounded-[6px]`} onClick={handleButtonClick}>
                    <div className={`w-[0.688rem] h-[0.688rem] bg-[#${dotColor}] rounded-full mr-2`}></div> {/* Dot */}
                    <div className={`text-[#${dotColor}] font-sm`}>{buttonText}</div> {/* Button Text */}
                </div>
            </div>

            <Modal showModal={showModal} setShowModal={setShowModal} />
        </>
    );
}

export { Button };
