import React from 'react';
import HorizontalBtns from "../../Components/CreateEvents/horizontalBtns";
import EventTable from '../../Components/CreateEvents/EventTable';
import EventList from '../../Components/CreateEvents/EventList';
import SwitchButtons from '../../Components/CreateEvents/SwitchButton';
import TopBar from '../../Components/CreateEvents/TopBar';
import SearchBar from '../../Components/CreateEvents/SearchBar';
import DashBar from '../../Components/CreateEvents/DashBar';
import Scanner from '../../Components/CreateEvents/Scanner'
import Pagination from '../../Components/CreateEvents/Pagination';

const Events = () => {
    return (
        <>
            <div className='flex flex-col items-center'>

                {/* Top Bar */}
                <div className='mt-8'>
                    <TopBar />
                </div>

                {/* Rest of the Content */}
                <div className='flex flex-row mt-8 gap-8'>
                    <div>
                        <EventList />
                    </div>

                    <div className='flex flex-col gap-6'>
                        <div className='flex flex-col gap-8 pb-4 border-b-[1px] border-[#282C31]'>
                            <DashBar />
                            <div className="flex justify-end">
                                <Scanner />
                            </div>
                        </div>

                        <div className='flex flex-row gap-10'>
                            <HorizontalBtns count='650' description='Total No.of.Attendee' />
                            <HorizontalBtns count='$12.5k' description='Total Revenue' />
                            <HorizontalBtns count='0' description='Total Check-In' />
                        </div>

                        <div className='font-gilroy text-lg font-semibold mt-2' style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}> Page Visitor Information </div>

                        <div className='flex flex-row gap-12 border-b-[1px] border-[#282C31] pb-4'>

                            <div className='w-[38.75rem] h-[20.938rem] bg-[#282C31] bg-opacity-30 rounded-2xl border-1px border-red-700'>
                            </div>

                            <div className='flex flex-col gap-2'>
                                <HorizontalBtns count='650' description='Total No.of.Attendee' />
                                <HorizontalBtns count='$12.5k' description='Total Revenue' />
                                <HorizontalBtns count='0' description='Total Check-In' />
                            </div>

                        </div>

                        <div className='flex flex-col gap-4 mb-8'>
                            <div className='flex justify-end'>
                                <SwitchButtons />
                            </div>

                            <div className='flex justify-end'>
                                <SearchBar />
                            </div>

                            <div className='flex justify-end'>
                                <EventTable />
                            </div>

                            <div className='flex justify-end'>
                                <Pagination />
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default Events;
