import React from 'react'

const NoData = () => {
    return (
        <div className='text-white items-center justify-center text-3xl flex h-[40vh] flex-col md:flex-row gap-8 border-[1px] border-[#282C31] w-full  mt-8 mb-4 rounded-xl p-4 bg-[#282C31] bg-opacity-30'>
            No Event Found
        </div>
    )
}

export default NoData