import React, { useState, useEffect } from "react";
import "./EventDetailsForm.css";

const EventDetailsForm = ({
  eventDetailsFormData,
  setEventDetailFormData,
  organizer,
  setOrganizer,
  imageUpload,
}) => {
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const validateInput = (name, value, type = "text") => {
    let error = "";
    switch (name) {
      case "description":
        if (!value) {
          error = "Event description is required";
        }
        break;
      case "featured_img_url":
        if (type === "file" && !value) {
          error = "Featured image is required";
        }
        break;
      case "banner_img_url":
        if (type === "file" && !value) {
          error = "Banner image is required";
        }
        break;
      case "event_type":
        if (!value) {
          error = "Event type is required";
        }
        break;
      case "event_mode":
        if (!value) {
          error = "Event mode is required";
        }
        break;
      case "location_first":
        if (!value) {
          error = "Event location is required";
        }
        break;
      case "location_last":
        if (!value) {
          error = "Event location is required";
        }
        break;
      case "organizer_name":
        if (!value) {
          error = "Organizer name is required";
        }
        break;
      case "profile_link":
        if (!value) {
          error = "Profile link is required";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleInputChange = async (event) => {
    console.log(eventDetailsFormData, "hello");
    const { name, value, type, files } = event.target;
    if (type === "file" && files.length > 0) {
      const fileUrl = await imageUpload(files[0]);
      validateInput(name, fileUrl, type);
      setEventDetailFormData((prevData) => ({
        ...prevData,
        [name]: fileUrl,
      }));
    } else {
      validateInput(name, value, type);
      setEventDetailFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBlur = (event) => {
    const { name, value, type } = event.target;
    validateInput(name, value, type);
  };

  const handleOrganizerChange = async (event, index) => {
    const { name, value, type, files } = event.target;
    if (type === "file" && files.length > 0) {
      const fileUrl = await imageUpload(files[0]);
      // validateInput(name, fileUrl, type);
      setOrganizer((prevOrganizers) => {
        const updatedOrganizers = [...prevOrganizers];
        updatedOrganizers[index] = {
          ...updatedOrganizers[index],
          [name]: fileUrl,
        };
        return updatedOrganizers;
      });
    } else {
      validateInput(name, value, type);
      setOrganizer((prevOrganizers) => {
        const updatedOrganizers = [...prevOrganizers];
        updatedOrganizers[index] = {
          ...updatedOrganizers[index],
          [name]: value,
        };
        return updatedOrganizers;
      });
    }
  };

  useEffect(() => {
    const checkErrors = Object.values(errors).some((error) => error !== "");
    setHasErrors(checkErrors);
  }, [errors]);

  const addOrganizer = (e) => {
    e.preventDefault();
    setOrganizer((prevOrganizers) => {
      const newOrganizer = { name: "", profile_link: "", img_url: "" };
      return [...prevOrganizers, newOrganizer];
    });
  };

  console.log(hasErrors, "erros", eventDetailsFormData, errors);

  return (
    <form>
      <div className="info-main-div flex flex-col py-5 mt-5 w-full rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:max-w-full">
        <div className="flex flex-col px-9 w-full max-md:px-5 max-md:max-w-full">
          <label
            htmlFor="message"
            className="block mb-2 text-md font-medium dark:text-white"
          >
            Write an Event Description*
          </label>
          <textarea
            name="description"
            value={eventDetailsFormData.description}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
            id="message"
            rows="4"
            className={`block bg-opacity-30 p-2.5 w-full text-sm bg-[#282C31] rounded-xl border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.description ? "border-red-500" : "border-[#727C8A30]"
            }`}
            placeholder="Add Event Description"
          ></textarea>
          {errors.description && (
            <span className="text-red-500 text-xs mt-1">
              {errors.description}
            </span>
          )}
          <div className="image-upload-div flex gap-5 my-5">
            <div className="featured-image w-2/5">
              <label>Add Featured Image*</label>
              <p className="text-xs mb-2">Image Size 400x400px | up to 10MB</p>
              <div className="flex items-center justify-center w-full">
                <div
                  onClick={() => document.getElementById("fileInput2").click()}
                  htmlFor="dropzone-file"
                  className="featured-image flex flex-col items-center justify-center w-full h-64 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <input
                    type="file"
                    id="fileInput2"
                    accept="image/*"
                    name="featured_img_url"
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    style={{ display: "none" }}
                  />
                  {eventDetailsFormData.featured_img_url !== "" ? (
                    <img
                      style={{ height: "100%" }}
                      src={eventDetailsFormData.featured_img_url}
                      alt="Uploaded"
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {errors.featured_img_url && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.featured_img_url}
                </span>
              )}
            </div>
            <div className="featured-image w-3/5 mb-2">
              <label htmlFor="banner-image">Add Banner Image*</label>
              <p className="text-xs mb-2">Image Size 1300x300px | up to 10MB</p>
              <div className="flex items-center justify-center w-full">
                <div
                  onClick={() => document.getElementById("fileInput").click()}
                  htmlFor="banner-image"
                  className="flex flex-col items-center justify-center w-full h-64 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    name="banner_img_url"
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                  />
                  {eventDetailsFormData.banner_img_url !== "" ? (
                    <img
                      style={{ height: "100%" }}
                      src={eventDetailsFormData.banner_img_url}
                      alt="Uploaded"
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {errors.banner_img_url && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.banner_img_url}
                </span>
              )}
            </div>
          </div>
          <div className="image-upload-div flex justify-between items-center gap-5 w-full max-md:ml-0 max-md:w-full">
            <div className="selector-drop flex w-1/2 flex-col grow text-base font-semibold text-neutral-300">
              <label htmlFor="event_type">Event Type</label>

              <select
                type="text"
                id="event_type"
                name="event_type"
                value={eventDetailsFormData.event_type}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="side or main"
                className={`bg-transparent h-[46px] justify-center  px-6 items-center mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                  errors.event_type
                    ? "border-red-500"
                    : "border-[#727C8A30] bg-zinc-800"
                }`}
              >
                <option value="">No Option Selected</option>
                <option value="side">Side</option>
                <option value="main">Main</option>
              </select>
              {/* <input
                                    type="text"
                                    id="event_type"
                                    name="event_type"
                                    value={eventDetailsFormData.event_type}
                                    onChange={handleInputChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    placeholder="Virtual or Offline"
                                    className={`bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${errors.event_type ? 'border-red-500' : 'border-[#727C8A30] bg-zinc-800'
                                        }`}
                                /> */}
              {errors.event_type && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.event_type}
                </span>
              )}
            </div>
            <div className="selector-drop flex w-1/2 flex-col grow text-base font-semibold text-neutral-300">
              <label htmlFor="event_mode">Event Mode</label>
              <select
                type="text"
                id="event_mode"
                name="event_mode"
                value={eventDetailsFormData.event_mode}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Virtual or Offline"
                className={`bg-transparent h-[46px] justify-center  px-6 items-center mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                  errors.event_type
                    ? "border-red-500"
                    : "border-[#727C8A30] bg-zinc-800"
                }`}
              >
                <option value="">No Option Selected</option>
                <option value="virtual">Virtual</option>
                <option value="offline">Offline</option>
              </select>
              {errors.event_mode && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.event_mode}
                </span>
              )}
            </div>
          </div>
          <hr className="h-px my-5 mx-[-2.25rem] bg-[#282C31] border-0"></hr>
          <label
            htmlFor="eventLocation"
            className="block  mb-2  text-md font-medium dark:text-white"
          >
            Add Event Location*
          </label>
          <div className="image-upload-div flex gap-5">
            <div className="featured-image w-1/2">
              <input
                type="text"
                id="eventLocation"
                name="location_first"
                value={eventDetailsFormData.location_first}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Hall Name, Building Name"
                className={`bg-transparent h-[46px] w-full justify-center items-start px-6 py-4 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                  errors.location_first
                    ? "border-red-500"
                    : "border-[#727C8A30] bg-zinc-800"
                }`}
              />
              {errors.location_first && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.location_first}
                </span>
              )}
            </div>
            <div className="featured-image w-1/2">
              <input
                type="text"
                id="eventLocationLink"
                name="location_last"
                value={eventDetailsFormData.location_last}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Add Map Link"
                className={`bg-transparent h-[46px] w-full justify-center items-start px-6 py-4 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                  errors.location_last
                    ? "border-red-500"
                    : "border-[#727C8A30] bg-zinc-800"
                }`}
              />
              {errors.location_last && (
                <span className="text-red-500 text-xs mt-1">
                  {errors.location_last}
                </span>
              )}
            </div>
          </div>
          <hr className="h-px my-8 mx-[-2.25rem] bg-[#282C31] border-0"></hr>
          <div className="flex h-[38px] w-full mb-2 justify-between items-center">
            <label htmlFor="organisers">Add Organizer*</label>
            <button
              onClick={addOrganizer}
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-[#727C8A] bg-opacity-30 rounded-xl dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                className="me-2"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  width="20"
                  height="20"
                  rx="10"
                  fill="#515861"
                />
                <path
                  d="M14 9.10688V10.9101H6V9.10688H14ZM10.9101 14H9.10688V6H10.9101V14Z"
                  fill="#D5D8DC"
                />
              </svg>
              Add More
            </button>
          </div>
          <div className="grid-cols-2 grid gap-4">
            {organizer.map((value, index) => (
              <div
                key={index}
                className="selector-drop w-full border rounded-xl p-2 h-full border-[#727C8A30] bg-[#282C31] bg-opacity-30 "
              >
                <div className="flex flex-col items-center justify-center w-full h-20 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <input
                    id="dropzone-file"
                    className="w-full hover:bg-[#282C31] hover:rounded-md"
                    name="img_url"
                    onChange={(e) => handleOrganizerChange(e, index)}
                    type="file"
                  />
                </div>

                <div className="relative">
                  <input
                    type="text"
                    id="OrganizerName"
                    value={value?.name}
                    name="name"
                    onChange={(e) => handleOrganizerChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Enter Organization Name"
                    className={`bg-transparent h-[46px] w-full justify-left items-start px-6 py-2 mt-2 text-sm text-left text-[#727C8A] rounded-xl border border-solid max-md:px-5 ${
                      errors.name
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.name && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="ProfileLink"
                    name="profile_link"
                    value={value?.profile_link}
                    onChange={(e) => handleOrganizerChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Profile link (Website Link)"
                    className={`bg-transparent h-[46px] w-full justify-left items-start px-6 py-2 mt-2 text-sm text-left text-[#727C8A] rounded-xl border border-solid max-md:px-5 ${
                      errors.profile_link
                        ? "border-red-500"
                        : "border-[#727C8A30] bg-zinc-800"
                    }`}
                  />
                  {errors.profile_link && (
                    <span className="text-red-500 text-xs mt-1">
                      {errors.profile_link}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EventDetailsForm;
