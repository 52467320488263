import React, { useEffect, useState } from 'react';

const QuantityPicker = ({ min, max, setTicketCount }) => {
    const [value, setValue] = useState(min);
    const [disableDec, setDisableDec] = useState(true);
    const [disableInc, setDisableInc] = useState(false);

    const increment = () => {
        const plusState = value + 1;
        if (value < max) {
            setValue(plusState);
            setDisableDec(false);
        }
        if (value === max - 1) {
            setDisableInc(true);
        }
        if (value === min) {
            setDisableDec(false);
        }
    };

    const decrement = () => {
        const minusState = value - 1;
        if (value > min) {
            setValue(minusState);
            if (value === min + 1) {
                setDisableDec(true);
            }
        } else {
            setValue(min);
        }
        if (value === max) {
            setDisableInc(false);
        }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        console.log(event,"quantity picker")
        setTicketCount(value);
    };

    useEffect(()=>{
        setTicketCount(value)
    },[value])
    
    return (
        <span className='flex border border-[#515861] rounded-[8px]'>
            <button
                className={`w-8 h-8 flex text-[#D5D8DC] items-center justify-center ${disableDec ? 'cursor-not-allowed' : ''} bg-transparent quantity-modifier modifier-left`}
                onClick={decrement}
                disabled={disableDec}
            >
                &ndash;
            </button>
            <input
                className="w-12 bg-[#282C31] text-[#D5D8DC] text-center"
                type="text"
                value={value}
                onChange={handleOnChange}
                readOnly
            />
            <button
                className={`w-8 h-8 flex text-[#D5D8DC] items-center justify-center ${disableInc ? 'cursor-not-allowed' : ''} bg-transparent quantity-modifier modifier-right`}
                onClick={increment}
                disabled={disableInc}
            >
                &#xff0b;
            </button>
        </span>
    );
};

export default QuantityPicker;
