import React, { useEffect } from 'react'
import NFT from "../../Assets/Images/NFT Image.png"
import { Button, Input } from '@material-tailwind/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCoupons, purchaseTicket } from '../../features/Events/EventSlice'
import { Link, useNavigate } from 'react-router-dom'
import { Bounce, ToastContainer, toast } from 'react-toastify';


const EventPaymentCard = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {selectedEvent}= useSelector((state)=>state.events)
    
    const purchaseTicketEvent = () => {
        const obj = {
            "event_id": selectedEvent[0]?._id,
            "ticket_id": selectedEvent[0]?.ticket_types[0]?.id,
            "trans_id": "",
            "chain": "",
            "checked_in": "",
            "nft_url": "",
            "first_name": props.form?.firstName,
            "last_name": props.form?.lastName,
            "email": props.form?.emailId,
            "phone_number": props.form?.contactNumber,
            "wallet_address": props.form?.walletAddress
        }

        dispatch(purchaseTicket(obj)).then(async (result)=>{
            if(result.payload)
                {
                    const data=result.payload
                    console.log(data,"purchase ticekt data")
                    if(data?.qr_url!=="")
                        {
                            toast('🦄 Purchased Successfully!!', {
                                position: "bottom-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                                transition: Bounce,
                                });
                            if(data?.txnHash){
                                const url = `https://www.oklink.com/amoy/tx/${data.txnHash}`
                                toast(<div>
                                    <a href={url} target='_blank'>Check your minted NFT!</a>
                                    </div>
                                  , {
                                    position: "bottom-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                    transition: Bounce,
                                    });
                            
                            }
                            // await new Promise(resolve => setTimeout(resolve, 6000)); 
                            // navigate("/")
                            // window.location.reload()
                        } 
                    }
                    else{
                        console.log("toast")
                        toast('Purchase Failed!!', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Bounce,
                            });
                    }
        })
    }

    useEffect(()=>{
        dispatch(fetchCoupons(selectedEvent[0]?._id)).then(async (result)=>{
            console.log(result)
        })
    },[selectedEvent[0]?._id])
    var totalPrice = props.ticketCount*props.tickets[0].price   
    var tax = props.ticketCount*props.tickets[0].price*0.1
    var total = totalPrice+tax

    return (
        <div className='flex flex-col gap-8 justify-center items-center'>
            <div className='flex flex-col gap-4 items-center justify-between border-[1px] border-[#636C77] w-full rounded-xl bg-[#74737333]'>
                <div className='w-full m-2 px-2 rounded-xl'>
                    <img src={NFT} className='rounded-xl w-full h-[100px] object-cover' alt="" />
                </div>
                <div>
                    <span className='text-white text-2xl'>Payment Details</span>
                </div>
                <hr class="h-px mt-1 mb-4 min-w-full mx-[-1rem] bg-[#282C3150]"></hr>

                <div className='px-4 w-full'>
                    <div className="relative flex mb-2 w-full max-w-[24rem]">
                        <Input
                            label="Promo Code"
                            className="pr-20 text-white border-[#727C8A50]"
                            containerProps={{
                                className: "min-w-0 ",
                            }}
                        />
                        <Button
                            size="sm"
                            // color={email ? "gray" : "blue-gray"}
                            className="!absolute right-1 top-1 rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 normal-case"
                        >
                            Apply Code
                        </Button>
                    </div>
                </div>

                <div className='w-full px-4 flex justify-between'>
                    <span className='text-white '>NFT Ticket 
                    {
                        (props.ticketCount>0)?<span className='text-[#A700E9]'> x {props.ticketCount}</span>:<></>
                    }
                     </span>
                    <span>-</span>
                    <span>
                    {
                        (props.ticketCount>0)?<span className='text-white '>${totalPrice}</span>:<></>
                    }
                    </span>
                </div>
                <div className='w-full px-4 flex justify-between'>
                    <span className='text-white '>Taxes</span>
                    <span>-</span>
                    <span>
                    {
                        (props.ticketCount>0)?<span className='text-white '>${tax}</span>:<></>
                    }
                    </span>
                </div>
                <hr class="h-px my-4 min-w-full mx-[-1rem] bg-[#282C3150]"></hr>
                <div className='w-full px-4 mb-5 flex justify-between'>
                    <span className='text-white text-2xl'>Grand Total</span>
                    <span>-</span>
                    <span>
                    {
                        (props.ticketCount>0)?<span className='text-white '>${total}</span>:<></>
                    }
                    </span>
                </div>
            </div>
            <div className='w-full'>
                <Button className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 normal-case w-full' onClick={purchaseTicketEvent}>Check Out</Button>
            </div>
        </div>
    )
}

export default EventPaymentCard