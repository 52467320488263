import search from '../../Assets/Images/search.svg';

const SearchBar = () => {
    return (
        <>
            <div className="flex pl-6 pr-2 flex-row justify-between items-center bg-[#282C31] w-[full] bg-opacity-30 h-[3rem] border-[#282C31] border-[1px] rounded-[12px]">
                <p className='font-gilroy text-xl' style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>
                    5ireChain Community Rewards Campaign ....
                </p>

                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search Name, E Mail..."
                        className="bg-[#282C31] bg-opacity-40 text-[#D5D8DC] py-2 pr-8 pl-10 font-normal w-[32.5rem] rounded-[10px] outline-none focus:bg-opacity-50"
                        style={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px'}}
                    />
                    <img src={search} alt="Search" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                </div>
            </div>
        </>
    );
}

export default SearchBar;
