import { Button, Step, Stepper } from "@material-tailwind/react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { addEvent } from "../../features/Events/EventSlice";
import BasicInfo from "./BasicInfo";
import EventDetailsForm from "./EventDetailsForm";
import SpeakerDetails from "./SpeakerDetails";
import { makeRequest } from "../../api/api";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import arrow from "../../Assets/SVG/arrow.svg";
import "./EventInfoForm.css";

function EventInfoForm() {
  const [formData, setFormData] = React.useState({
    eventTitle: "",
    eventURL: "",
    timeZone: "(GMT+05:30) India Standard Time - Colombo",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    websiteURL: "",
    facebookURL: "",
    twitterURL: "",
    linkedinURL: "",
    discordURL: "",
    telegramURL: "",
  });

  const [payload, setPayload] = React.useState({});
  const [eventDetailsFormData, setEventDetailFormData] = React.useState({
    description: "",
    featured_img_url: "",
    banner_img_url: "",
    event_mode: "",
    event_type: "",
    location_first: "",
    location_last: "",
    location: "asdf",
    organizer: [],
  });

  const [organizer, setOrganizer] = React.useState([
    {
      name: "",
      profile_link: "",
      img_url: "",
    },
  ]);

  const [speakerDetails, setSpeakerDetails] = React.useState([
    {
      name: "",
      designation: "",
      img_url: "",
    },
  ]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [hasErrors, setHasErrors] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageUpload = async (fileInput) => {
    const formdata = new FormData();
    formdata.append(
      "image",
      fileInput,
      "/C:/Users/Valia/Downloads/quantum_computing.jpeg"
    );
    formdata.append("entity", "banner");
    const data = await makeRequest(
      "POST",
      "apps/events/upload-image/",
      formdata
    );

    return data.data.message;
  };

  const checkBasicInfoFields = () => {
    const newErrors = {};
    if (!formData.eventTitle) newErrors.eventTitle = "Event title is required";
    if (!formData.eventURL) newErrors.eventURL = "Event URL is required";
    if (!formData.startDate) newErrors.startDate = "Start date is required";
    if (!formData.endDate) newErrors.endDate = "End date is required";
    if (!formData.startTime) newErrors.startTime = "Start time is required";
    if (!formData.endTime) newErrors.endTime = "End time is required";
    if (!formData.timeZone) newErrors.timeZone = "Time zone is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkEventDetailsFields = () => {
    const newErrors = {};
    if (!eventDetailsFormData.description)
      newErrors.description = "Event description is required";
    if (!eventDetailsFormData.featured_img_url)
      newErrors.featured_img_url = "Featured image is required";
    if (!eventDetailsFormData.banner_img_url)
      newErrors.banner_img_url = "Banner image is required";
    if (!eventDetailsFormData.event_mode)
      newErrors.event_mode = "Event mode is required";
    if (!eventDetailsFormData.event_type)
      newErrors.event_type = "Event type is required";
    if (!eventDetailsFormData.location_first)
      newErrors.location_first = "Location is required";
    if (!eventDetailsFormData.location_last)
      newErrors.location_last = "Location is required";
    if (organizer.some((org) => !org.name || !org.profile_link || !org.img_url))
      newErrors.organizer = "All organizer fields are required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkSpeakerDetailsFields = () => {
    const newErrors = {};
    if (
      speakerDetails.some(
        (speaker) => !speaker.name || !speaker.designation || !speaker.img_url
      )
    )
      newErrors.speaker = "All speaker fields are required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    let isValid = false;
    if (activeStep === 0) {
      isValid = checkBasicInfoFields();
    } else if (activeStep === 1) {
      isValid = checkEventDetailsFields();
    }

    if (isValid) {
      if (activeStep === 0) {
        const basic_info = {
          title: formData.eventTitle,
          start_date: formData.startDate,
          end_date: formData.endDate,
          start_time: formData.startTime,
          end_time: formData.endTime,
          event_slug: formData.eventURL,
          timezone: formData.timeZone,
          currency: "USD",
          social_media_links: {
            telegram: formData.telegramURL,
            twitter: formData.twitterURL,
            website: formData.websiteURL,
            facebook: formData.facebookURL,
            linkedIn: formData.linkedinURL,
            discord: formData.discordURL,
          },
        };
        const obj = { ...payload, basic_info: basic_info };
        setPayload(obj);
      } else if (activeStep === 1) {
        const event_details = {
          description: eventDetailsFormData.description,
          featured_img_url: eventDetailsFormData.featured_img_url,
          banner_img_url: eventDetailsFormData.banner_img_url,
          event_mode: eventDetailsFormData.event_mode,
          event_type: eventDetailsFormData.event_type,
          location_first: eventDetailsFormData.location_first,
          location_last: eventDetailsFormData.location_last,
          location:
            eventDetailsFormData.location_first +
            " " +
            eventDetailsFormData.location_last,
        };
        const obj = {
          ...payload,
          event_details: { ...event_details, organizer: organizer },
        };
        setPayload(obj);
      }
      !isLastStep && setActiveStep((cur) => cur + 1);
      setHasErrors(false);
    } else {
      setHasErrors(true);
    }
  };

  const publishEvent = () => {
    if (checkSpeakerDetailsFields()) {
      const obj = { ...payload, speaker_details: speakerDetails };
      setPayload(obj);
      dispatch(addEvent(obj)).then(async (result) => {
        toast("🦄 Event Published Successfully!!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
        await new Promise((resolve) => setTimeout(resolve, 5000));
        navigate("/event/TicketAndPayment");

        // console.log(result)
        // console.log(result, "add event response")
      });
    } else {
      setHasErrors(true);
    }
  };

  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div className="flex text-[#D5D8DC] flex-col max-w-[950px]">
      <div className="dashbar flex gap-5 px-7 py-2.5 w-full rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className=" flex-auto my-auto text-[#727C8A] bg-transparent text-2xl font-semibold bg-clip-text">
          Event Info
        </div>
        <div className="links relative w-1/2 flex gap-3 py-2.5 pr-2 pl-5 text-sm rounded-2xl bg-[#282C31] bg-opacity-30">
          <div className="flex-auto my-auto text-neutral-300">
            https://abc.events/5irereward...
          </div>
          <Button
            size="sm"
            color="purple"
            className="!absolute right-1 top-1 rounded-xl"
          >
            Copy Url
          </Button>
        </div>
        <div className="arrow">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className="stepper-thingy mt-2">
        <Stepper
          activeStep={activeStep}
          isLastStep={(value) => setIsLastStep(value)}
          isFirstStep={(value) => setIsFirstStep(value)}
        >
          <Step className="bg-green-400" onClick={() => setActiveStep(0)}>
            1
          </Step>
          <Step onClick={() => setActiveStep(1)}>2</Step>
          <Step onClick={() => setActiveStep(2)}>3</Step>
        </Stepper>
      </div>
      {activeStep === 0 ? (
        <BasicInfo formData={formData} setFormData={setFormData} />
      ) : activeStep === 1 ? (
        <EventDetailsForm
          imageUpload={imageUpload}
          eventDetailsFormData={eventDetailsFormData}
          setEventDetailFormData={setEventDetailFormData}
          organizer={organizer}
          setOrganizer={setOrganizer}
        />
      ) : (
        <SpeakerDetails
          imageUpload={imageUpload}
          speakerDetails={speakerDetails}
          setSpeakerDetails={setSpeakerDetails}
        />
      )}

      <div className="bottom-link mt-16 flex flex-col items-center">
        {hasErrors && (
          <div className="mb-4 text-red-500">
            All fields are not filled. Kindly fill the form above before
            proceeding.
          </div>
        )}
        <div className="flex justify-between w-full">
          <Button onClick={handlePrev} disabled={isFirstStep}>
            Prev
          </Button>
          {isLastStep ? (
            <Button onClick={publishEvent}>Publish Event</Button>
          ) : (
            <Button onClick={handleNext} type="submit">
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventInfoForm;
