import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = ["S.No", "Name", "E-mail ID", "Payment Mode", "Discount/Promo", "Check-In"];

const TABLE_ROWS = [
    {
        sno: "01",
        name: "John Michael",
        email: "john@example.com",
        paymentMode: "Credit Card",
        discountPromo: "SUMMER25",
        checkIn: "2024-05-01",
    },
    {
        sno: "02",
        name: "Alexa Liras",
        email: "alexa@example.com",
        paymentMode: "PayPal",
        discountPromo: "FALL10",
        checkIn: "2024-04-30",
    },
    {
        sno: "03",
        name: "Laurent Perrier",
        email: "laurent@example.com",
        paymentMode: "Bank Transfer",
        discountPromo: "PROMO",
        checkIn: "2024-04-29",
    },
    {
        sno: "04",
        name: "Michael Levi",
        email: "michael@example.com",
        paymentMode: "Credit Card",
        discountPromo: "PROMO",
        checkIn: "2024-04-28",
    },
    {
        sno: "05",
        name: "Richard Gran",
        email: "richard@example.com",
        paymentMode: "PayPal",
        discountPromo: "SPRING15",
        checkIn: "2024-04-27",
    },
];

const EventTable = () => {
    return (
        <Card className="rounded-[20px] h-full w-full overflow-hidden border-[1px] border-[#636C77] bg-transparent" style={{ maxWidth: '59.375rem', maxHeight: '25rem' }}>
            <table className="w-full min-w-max table-auto text-left text-[#EBECEE]">
                <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                            <th key={head} className="border-b-[1px] border-[#636C77] bg-[#282C31] bg-opacity-70 p-4">
                                <Typography
                                    variant="small"
                                    color="#EBECEE"
                                    className="font-semibold text-lg leading-none opacity-70"
                                >
                                    {head}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {TABLE_ROWS.map(({ sno, name, email, paymentMode, discountPromo, checkIn }) => (
                        <tr key={sno}>
                            <td className="p-4">
                                <Typography variant="small" color="#EBECEE" className="text-base font-normal">
                                    {sno}
                                </Typography>
                            </td>
                            <td className="p-4">
                                <Typography variant="small" color="#EBECEE" className="text-base font-normal">
                                    {name}
                                </Typography>
                            </td>
                            <td className="p-4">
                                <Typography variant="small" color="#EBECEE" className="text-base font-normal">
                                    {email}
                                </Typography>
                            </td>
                            <td className="p-4">
                                <Typography variant="small" color="#EBECEE" className="text-base font-normal">
                                    {paymentMode}
                                </Typography>
                            </td>
                            <td className="p-4">
                                <Typography variant="small" color="#EBECEE" className="text-base font-normal">
                                    {discountPromo}
                                </Typography>
                            </td>
                            <td className="p-4">
                                <button
                                    className="text-[#EBECEE] bg-gradient-to-r from-[#6042FF] via-[rgba(96, 66, 255, 0.7)] to-[#6042FF] px-4 py-2 rounded-xl flex items-center justify-center shadow-inner"
                                    style={{ width: '7.5rem', height: '2rem' }} // equivalent to 120px width and 33px height in rem
                                >
                                    Check-In
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
}

export default EventTable;
