import React, { useState } from 'react';
import event from '../../Assets/Images/event.png';
import Export from '../../Assets/Images/export.svg'
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogBody } from '@material-tailwind/react';
import { useDispatch } from 'react-redux';
import { CheckinToEvent } from '../../features/Events/EventSlice';
import Avatar from './Avatar';

const Card = ({ data, type }) => {
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    const month = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [img, setImage] = useState("")
    const [open, setOpen] = useState(false)
    const checkInEventHandler = () => {
        console.log(data)
        setOpen(true)
        setImage(data?.qr_url)
    }
    return (
        <div className='flex justify-center pl-4 pr-4'>
            <div className='flex flex-col md:flex-row gap-8 border-[1px] border-[#282C31] w-full h-full mt-8 mb-4 rounded-xl p-4 bg-[#282C31] bg-opacity-30'>
                {/* Image for the event */}
                <div className='flex justify-center'>
                    <img src={data?.event_details?.featured_img_url} alt='event' />
                </div>

                {/* Details for the event */}
                <div className='flex flex-col flex-grow pr-4 justify-between'>
                    {/* Buttons */}
                    <div className='flex flex-row justify-between items-center mb-4 gap-4 flex-wrap'>
                        <div className='flex flex-col md:flex-row gap-4 items-center'>
                            <button className='rounded-lg w-full md:w-[8.75rem] h-[2.125rem] bg-[#B4009040] text-[#E900BA] px-4'>NFT Included</button>
                            {data?.is_approved_by_admin && <button className='rounded-lg w-full md:w-[11.25rem] h-[2.125rem] bg-[#6E33094D] text-[#F67314] px-4'>Approval Required</button>}
                        </div>

                        <div className='flex flex-row gap-1'>
                            <Avatar />
                            <div className='flex items-center text-[#727C8A] bg-[#282C31] bg-opacity-40 font-normal px-3 rounded-[10px] '>
                                <p>20+ Attendees</p>
                            </div>
                        </div>
                    </div>

                    {/* Event Name */}
                    <div className='text-[#D5D8DC] font-gilroy text-3xl pb-4 border-b-[1px] border-[#282C31]'>
                        {data?.basic_info?.title}
                    </div>

                    <div className='mt-4 text-[#727C8A] font-semibold gap-4 flex items-center'>
                        <Avatar />
                        by {data?.event_details?.organizer[0]?.name}
                    </div>


                    <div className="flex flex-col justify-between md:flex-row mt-8 flex-wrap gap-6">
                        <div className="flex flex-col md:flex-row gap-4 items-center">
                            <div className='flex-col justify-center items-center text-center md:flex-row gap-8 border-[1px] border-[#282C31] rounded-xl p-4 bg-[#282C31] bg-opacity-30 '>
                                <p class="text-[#EBECEE] font-bold">{days[new Date(data?.basic_info?.start_date).getDay()]}</p>
                                <p class="text-[#A2A9B3]">{new Date(data?.basic_info?.start_date).getDate()}</p>
                            </div>
                            <div className="flex flex-col items-center md:items-start">
                                <p className="text-[#EBECEE] font-bold">{data?.basic_info?.start_date} to {data?.basic_info?.end_date}</p>
                                <p className="text-[#A2A9B3]">{data?.basic_info?.start_time} to {data?.basic_info?.end_time}</p>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-4 items-center">
                            {type === "all" && data?.qr_url !== "" &&
                                <>
                                    <button onClick={() => {
                                        navigate(`/eventInfo/${data?._id}`)
                                    }} className="flex justify-center items-center text-[#BDC2C9] w-full md:w-[10rem] bg-[#74737333] bg-opacity-20 font-medium h-[3rem] rounded-xl hover:bg-[#74737388] transition duration-300">
                                        <span>View Details</span>
                                        <img src={Export} alt="Export Icon" className="ml-2" />
                                    </button>
                                    <Button data-ripple-light="true" data-dialog-target="animated-dialog" onClick={checkInEventHandler} style={{
                                        background: 'linear-gradient(90deg, #A700E9 0%, #E900BA 100%)',
                                        boxShadow: '0px 4px 2px 0px #00000040 inset'
                                    }} className="text-[#FFFFFF] w-full md:w-[10rem] bg-[#74737333] h-[3rem] rounded-xl font-bold hover:bg-[#A700E9] transition duration-300">
                                        Check-In
                                    </Button>
                                </>
                            }
                            {
                                type === "all" && data?.qr_url === "" &&
                                <button onClick={() => {
                                    navigate(`/eventInfo/${data?._id}`)
                                }} className="flex justify-center items-center text-[#BDC2C9] w-full md:w-[10rem] bg-[#74737333] bg-opacity-20 font-medium h-[3rem] rounded-xl hover:bg-[#74737388] transition duration-300">
                                    <span>Manage</span>
                                    <img src={Export} alt="Export Icon" className="ml-2" />
                                </button>
                            }
                            {
                                (type !== "all" && type !== "manage")&&
                                <button onClick={() => {
                                    navigate(`/eventInfo/${data?._id}`)
                                }} className="flex justify-center items-center text-[#BDC2C9] w-full md:w-[10rem] bg-[#74737333] bg-opacity-20 font-medium h-[3rem] rounded-xl hover:bg-[#74737388] transition duration-300">
                                    <span>View Details</span>
                                    <img src={Export} alt="Export Icon" className="ml-2" />
                                </button>
                            }
                            {
                                type==="manage" &&
                                <button onClick={() => {
                                    localStorage.setItem("RecentlyCreatedEvent",data?._id)
                                    navigate(`/event/`)

                                }} className="flex justify-center items-center text-[#BDC2C9] w-full md:w-[10rem] bg-[#74737333] bg-opacity-20 font-medium h-[3rem] rounded-xl hover:bg-[#74737388] transition duration-300">
                                    <span>Manage</span>
                                    <img src={Export} alt="Export Icon" className="ml-2" />
                                </button>
                            }
                            <Dialog open={open} className='LoginCard !static' size='sm' handler={() => setOpen(false)}>
                                <DialogBody>
                                    <img src={img} alt="" />
                                </DialogBody>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
