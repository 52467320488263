import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchEventList = createAsyncThunk('events/fetchEvents',
    async () => {
        try {
            const response = await makeRequest("GET", `apps/events/fetch-all-events/`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
)

export const fetchPurchasedEvents = createAsyncThunk('events/fetchPurchasedEvents',
async () => {
    try {
        const response = await makeRequest("GET", `apps/events/get-tickets/`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
)

export const fetchCoupons = createAsyncThunk('events/fetchCoupons',
async (payload) => {
    try {
        const response = await makeRequest("GET", `apps/events/get-coupan-codes/?event_id=${payload}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
)


export const addEvent = createAsyncThunk('events/addEvent',
    async (payload) => {
        try {
            const response = await makeRequest("POST",`apps/events/create-event/`,payload);
            console.log(response.data, "response inside addEvent thunk")
            localStorage.setItem("RecentlyCreatedEvent", response.data.id)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const insertTicket= createAsyncThunk('events/insertTicket',
    async (payload)=>{
        try {
            const response = await makeRequest("POST",`apps/events/insert-ticket/`,payload);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
)

export const approveByAdmin = createAsyncThunk('events/ApproveByAdmin',
async (payload)=>{
    try {
        const response = await makeRequest("PUT",`apps/admin/approve-event/?id=${localStorage.getItem('RecentlyCreatedEvent')}`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
)
export const cancelEvent = createAsyncThunk('events/cancelEvent',
async (payload)=>{
    try {
        const response = await makeRequest("PUT",`apps/events/cancel-event/?id=${localStorage.getItem('RecentlyCreatedEvent')}`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
)


export const fetchSelectedEvent = createAsyncThunk('events/SelectedEvent',
    async (payload) => {
        try {
            const response = await makeRequest("GET", `apps/events/fetch-event/?keyword=${payload}`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }

)


export const purchaseTicket = createAsyncThunk('events/purchaseTicket',
    async (payload) => {
        try {
            const response = await makeRequest("POST", `apps/events/ticket-purchase/`, payload);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
)

export const CheckinToEvent = createAsyncThunk('events/getTicket',
async (payload) => {
    try {
        const response = await makeRequest("POST", `/apps/events/check-qr-code/`,payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}
)


export const fetchAttendees = createAsyncThunk('events/getAttendees',
async (payload) => {
    try {
        const response = await makeRequest("GET", `/apps/events/attendies_list/?event_id=6648841d101d3757c7ad2b79`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
)


export const fetchDraftsEvents = createAsyncThunk('events/getDrasftEvents',
async (payload) => {
    try {
        const response = await makeRequest("GET", `/apps/events/fetch-all-events/?user=hy`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
)

const eventSlice = createSlice({
    name: "events",
    initialState: {
        status: null,
        error: null,
        selectedEvent: null,
        events: [],
        purchasedEvents: [],
        attendees: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventList.fulfilled, (state, action) => {
            state.events = action.payload
        })
            .addCase(fetchSelectedEvent.fulfilled, (state, action) => {
                state.selectedEvent = action.payload
            })
            .addCase(fetchPurchasedEvents.fulfilled, (state,action) =>{
                state.purchasedEvents = action.payload
            })
            .addCase(fetchAttendees.fulfilled, (state,action)=>{
                state.attendees= action.payload
            })
    }
})

export default eventSlice.reducer