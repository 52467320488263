import React, { useState } from 'react';
import left from '../../Assets/Images/arrow-left.svg'
import grid from '../../Assets/Images/grid.svg'
import hash from '../../Assets/Images/hash.svg'
import ticket from '../../Assets/Images/ticket-2.svg'
import settings from '../../Assets/Images/settings.svg'
import person from '../../Assets/Images/person.svg'
import { useNavigate } from 'react-router-dom';

const EventList = () => {
    const [activeTab, setActiveTab] = useState('');

    const navigate= useNavigate()
    const handleTabClick = (tab) => {
        navigate(`./${tab}`)
        setActiveTab(tab);
    };

    return (
        <>
            <div className='w-[18.125rem] h-[46.25rem] text-[#D5D8DC] bg-[#74737333] border-[1px] border-[#282C31] rounded-2xl flex flex-col'>
                <div className='flex flex-row gap-[1rem] text-[#D5D8DC] items-center mt-4 pl-8 pb-4 border-b-[1px] border-[#282C31]'>
                    <img src={left} alt='left' style={{ height: '2rem' }} />
                    <div className='text-2xl leading-none' style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>Events List</div>
                </div>

                <div className='flex flex-col gap-4 mt-4 pl-8'>
                    <div className={`flex pl-4 text-18px h-10 items-center gap-8  flex-row  ${activeTab === 'eventDashboard' ? 'bg-[#282C31] rounded-bl-lg rounded-tl-lg' : 'hover:bg-[#282C31] hover:rounded-bl-lg hover:rounded-tl-lg'}`} onClick={() => handleTabClick('eventDashboard')}>
                        <img src={grid} alt='grid'/>
                        <div>Dashboard</div>
                    </div>

                    <div className={`flex pl-4 text-18px h-10 items-center gap-8  flex-row  ${activeTab === 'eventInfo' ? 'bg-[#282C31] rounded-bl-lg rounded-tl-lg' : 'hover:bg-[#282C31] hover:rounded-bl-lg hover:rounded-tl-lg'}`} onClick={() => handleTabClick('eventInfo')}>
                        <img src={hash} alt='hash'/>
                        <div>Event Info</div>
                    </div>

                    <div className={`flex pl-4 text-18px h-10 items-center gap-8 flex-row  ${activeTab === 'TicketAndPayment' ? 'bg-[#282C31] rounded-bl-lg rounded-tl-lg' : 'hover:bg-[#282C31] hover:rounded-bl-lg hover:rounded-tl-lg'}`} onClick={() => handleTabClick('TicketAndPayment')}>
                        <img src={ticket} alt='ticket'/>
                        <div>Ticket & Payment</div>
                    </div>

                    <div className={`flex pl-4 text-18px h-10 items-center gap-8 flex-row  ${activeTab === 'Sub Events' ? 'bg-[#282C31] rounded-bl-lg rounded-tl-lg' : 'hover:bg-[#282C31] hover:rounded-bl-lg hover:rounded-tl-lg'}`} onClick={() => handleTabClick('Sub Events')}>
                        <img src={person} alt='person'/>
                        <div>Sub Events</div>
                    </div>

                    <div className={`flex pl-4 text-18px h-10 items-center gap-8  flex-row ${activeTab === 'eventSettings' ? 'bg-[#282C31] rounded-bl-lg rounded-tl-lg' : 'hover:bg-[#282C31] hover:rounded-bl-lg hover:rounded-tl-lg'}`} onClick={() => handleTabClick('eventSettings')}>
                        <img src={settings}  alt='setting'/>
                        <div>Event Settings</div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default EventList;
