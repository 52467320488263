import { Button } from '@material-tailwind/react'
import React from 'react'
import Frame from "../../Assets/Images/Frame.png"

const EventBookNow = ({ tickets, setPayment }) => {
    const remaining_tickets = tickets?.[0]?.active_slots
    const ticket_price = tickets?.[0]?.price
    return (
        <div className='flex justify-center items-center'>
            <div className='flex justify-between items-center border-[1px] border-[#282C31] w-full rounded-[16px] p-6 bg-[#282C31] bg-opacity-30'>
                <div className='flex flex-col '>
                    <span className=' text-2xl text-spanish'>
                        {remaining_tickets} Spots Remaining
                    </span>
                    <span className='text-white'>
                        Hurry up and register before sells out
                    </span>
                </div>
                <div>
                    {/* needs to be dynamic */}
                    {/* <img src={Frame} alt={Frame} /> */}
                    <div className="text-gray-400 text-xl font-bold font-900">
                        ${ticket_price} /Per Person
                    </div>
                </div>
                <div className='flex items-center'>
                    <Button onClick={() => { setPayment(true) }} className='w-[12.75rem] normal-case' style={{
                        background: 'linear-gradient(90deg, #A700E9 0%, #E900BA 100%)',
                        boxShadow: '0px 4px 2px 0px #00000040 inset'
                    }}>
                        Book Now
                    </Button>
                </div>


            </div>
        </div>
    )
}

export default EventBookNow
