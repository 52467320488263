import React from 'react';
import event1 from '../../Assets/Images/event_button1.png';
import event2 from '../../Assets/Images/event_button2.png';
import event3 from '../../Assets/Images/event_button3.png';
import { useNavigate } from 'react-router-dom';

const EventButtons = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col lg:flex-row justify-center items-center pt-8 lg:space-x-4 px-4 lg:px-0">
            <img onClick={() => { navigate("/rewards") }} src={event1} alt="Event 1" className="mb-4 lg:mb-0 hover:opacity-75" />
            <img onClick={() => { navigate("/my-events") }} src={event2} alt="Event 2" className="mb-4 lg:mb-0 hover:opacity-75" />
            <div className="homeButtons cursor-pointer" onClick={() => { navigate("/event/eventInfo") }}>
                <img src={event3} alt="Event 3" className="hover:opacity-75" />
            </div>
        </div>
    );
}

export default EventButtons;
