import '@rainbow-me/rainbowkit/styles.css';
import "./App.css"
import "./css/index.css"
import LoginPage from "./pages/loginpage/LoginPage";
import SignPage from "./pages/signuppage/SignupPage";
import Dashboard from "./pages/dashboard/Dashboard";
import { walletConfig } from "./app/wallet";
import { Routes, Route } from "react-router";
import { useEffect } from "react";
import "./App.css"
import "./css/index.css"
import { useDispatch, useSelector } from "react-redux";
import { restoreLogin } from "./features/Login/UserSlice";
import { RainbowKitProvider} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {QueryClientProvider,QueryClient} from "@tanstack/react-query";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {

  const queryClient = new QueryClient();

  const dispatch = useDispatch()

  const { login,user } = useSelector((state) => state.user)

  useEffect(()=>{
    dispatch(restoreLogin())
  },[])


  return (
    
    <WagmiProvider config={walletConfig}>
    <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
            <div className="App">
            <ToastContainer />
              <Routes>
                <Route path='/*' element={login && user!==null? <Dashboard /> : <LoginPage/>} />
                <Route path='/signup' element={<SignPage />} />
              </Routes>
            </div>
        </RainbowKitProvider>
     </QueryClientProvider>
   </WagmiProvider>
  );
}

export default App;
