import React, { useEffect, useState } from 'react';
// import pfp from '../../Assets/Images/pfp.svg';
import cover from '../../Assets/Images/cover.png';
import './Banner.css';
import { Button } from '@material-tailwind/react';
import { useDispatch } from 'react-redux';
import { followUser } from '../../features/Login/UserSlice';

const Banner = ({profileData}) => {
    const dispatch = useDispatch();
    const [toggleFollow,setToggleFollow]=useState(profileData?.follow)
    const [count,setCount] = useState(profileData.follow? -1:-0) 
    const handleFollow = () =>{
        dispatch(followUser(profileData?._id)).then((res)=>{
            console.log(res, "follow result")
            if (res.payload.message === "success"){
                setCount(count+1)
                setToggleFollow(!toggleFollow)
            }
        })
    }
    useEffect(()=>{
        setToggleFollow(profileData?.follow)
    },[profileData])
    
    return (
        <div className="banner-container mt-10 mx-5 w-[{100%,40%}]">
            {/* <img src={profileData?.banner_url} alt="Cover" className='w-full rounded-3xl' /> */}
            <img src={cover} alt="Cover" className='w-full rounded-3xl' />
            <div className="flex flex-col md:flex-row mt-[-50px] items-start  md:items-end justify-around">
                
                {window.location.pathname!=="/profile"?
                <>
                <div className="flex flex-row items-end mb-5 md:mb-0">
                <img src={profileData?.profile_pic_url} alt="Profile" className="h-40 border-black border-[5px] rounded-[50%]" />
                {/* <img src={pfp} alt="Profile" className="h-40 border-black border-[5px] rounded-[50%]" /> */}
                <div className='flex flex-row gap-4 justify-center text-white '>
                    <div className="text-center">
                        <p className='text-xl text-[#E4E4E4]'>{profileData?.followers + ((count%2===0)?0:1)}</p>
                        <p className='font-light text-sm text-[#636C77]'>Followers</p>
                    </div>
                    <div className="text-center">
                        <p className='text-xl text-[#E4E4E4]'>{profileData?.following}</p>
                        <p className='font-light text-sm text-[#636C77]'>Following</p>
                    </div>
                </div>
                </div>
                <Button
                    onClick={handleFollow}
                    style={{
                        background:
                            "linear-gradient(90deg, #A700E9 0%, #E900BA 100%)",
                    }}
                    className="text-white w-full md:w-[10rem] bg-[#74737333] h-[3rem] rounded-xl font-bold hover:bg-[#A700E9] transition duration-300"
                >
                    {(toggleFollow)?<>Unfollow</>:<>Follow</>}
                </Button>
                </>
                :
                <div className="flex flex-row items-end mb-5 md:mb-0 w-4/5">
                <img src={profileData?.profile_pic_url} alt="Profile" className="h-40 border-black border-[5px] rounded-[50%]" />
                {/* <img src={pfp} alt="Profile" className="h-40 border-black border-[5px] rounded-[50%]" /> */}
                <div className='flex flex-row gap-4 justify-center text-white '>
                    <div className="text-center">
                        <p className='text-xl text-[#E4E4E4]'>{profileData?.followers + ((count%2===0)?0:1)}</p>
                        <p className='font-light text-sm text-[#636C77]'>Followers</p>
                    </div>
                    <div className="text-center">
                        <p className='text-xl text-[#E4E4E4]'>{profileData?.following}</p>
                        <p className='font-light text-sm text-[#636C77]'>Following</p>
                    </div>
                </div>
                </div>
                }
            </div>
        </div>
    );
}

export default Banner;
