import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { makeRequest } from "../../api/api";
import { hostname } from "../../data/data";


export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (userCredentials) => {
        try {
            const response = await axios.post(`${hostname}/apps/user/login/`, userCredentials);
            const token = response.data.token;
            return { user: "Viki", token };
        } catch (error) {
            throw error;
        }
    }
);


export const loginOtp = createAsyncThunk(
    'user/loginOtp',
    async (userCredentials) => {
        try {
            const response = await axios.post(`${hostname}/apps/user/send_otp/`, userCredentials);
            const token = response.data.token;
            return { user: "Viki", token, mobile: userCredentials.mobile };
        } catch (error) {
            throw error;
        }
    }
);

export const fetchUser = createAsyncThunk('user/fetchUser',
    async (payload) => {
        try {
            const response = await makeRequest("GET", `apps/user/get_user/?username=${payload}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

)

export const verifyOtp = createAsyncThunk(
    'user/verifyOtp',
    async (userCredentials) => {
        try {
            const response = await axios.post(`${hostname}/apps/user/verify_otp/`, userCredentials);
            const token = response.data.token;
            return { user: "Viki", token };
        } catch (error) {
            return  error;
        }
    }
);

export const profile = createAsyncThunk(
    'user/profile',
    async () => {
        try {
            const token = localStorage.getItem("Authorization");
            if (!token) {
                throw new Error('Token not found');
            }
            const response = await makeRequest("get", `${hostname}apps/user/get_user/`);
            console.log(response)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const followUser = createAsyncThunk(
    'followUser',
    async (_id) => {
        try {
            const token = localStorage.getItem("Authorization");
            const response = await makeRequest("post", `${hostname}apps/user/follow_user/`, {"id":_id,"type":"like"});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const signupUser = createAsyncThunk(
    'signup',
    async (userCredentials) => {
        try {
            const response = await makeRequest("post", `${hostname}auth/create_user/`, userCredentials);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);
export const updateProfile = createAsyncThunk(
    'user/updateProfile',
    async (profileData) => {
        try {
            const response = await makeRequest("post", `${hostname}apps/user/update_user_profile/`, profileData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);
export const googleLoginUser = createAsyncThunk(
    'user/googleLogin',
    async (userCredentials) => {
        try {
            const response = await makeRequest("post", `${hostname}apps/user/google-auth/`, userCredentials);
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
)

export const getMessage = async (userWallet) => {
    try {
        const response = await makeRequest("post", `${hostname}apps/user/request_message/`, userWallet);
        const message = response.data;
        return message.message;
    } catch (error) {
        throw error;
    }
};

export const verifySignature = createAsyncThunk( 
    "verifySignature",
    async (signedMessage) => {
    try {
        const response = await makeRequest("post", `${hostname}apps/user/call_verify_signature/`, signedMessage);
        const token = response.data.token;
        return { user: "Viki", token };

    } catch (error) {
        throw error;
    }
});

export const fetchUserId = createAsyncThunk( 
    "getUserID",
    async (user_id) => {
    try {
        const response = await makeRequest("get", `${hostname}apps/user/get_username/?user_id=${user_id}`);
        return response?.data

    } catch (error) {
        throw error;
    }
});



const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        error: null,
        otp: false,
        login: false,
        mobile:"",
        openProfile:null
    },
    reducers: {
        restoreLogin: (state) => {
            const storedToken = localStorage.getItem("Authorization");
            const user = localStorage.getItem("user")
            if (storedToken && user) {
                state.login = true;
                state.user = JSON.parse(user)
            }
            else {
                state.login = false
                state.user = null
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginOtp.pending, (state) => {
                // state.otp = false
            })
            .addCase(loginOtp.fulfilled, (state,action) => {
                state.otp = true
                state.mobile=action.payload.mobile
            })
            .addCase(googleLoginUser.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.error = null;
            })
            .addCase(googleLoginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                if(action.payload.token===undefined){
                    state.error="Error Occur"
                }
                else{
                state.login = true; 
                localStorage.setItem("Authorization", `${action.payload.token}`);
                }
            })
            .addCase(googleLoginUser.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                if(action.payload.token===undefined){
                    state.error="Error Occur"
                }
                else{
                state.login = true; 
                localStorage.setItem("Authorization", `${action.payload.token}`);
                }
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.error = null;
            })
            .addCase(verifySignature.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                state.login = true;
                localStorage.setItem("Authorization", `${action.payload.token}`);
            })
            .addCase(verifySignature.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(verifySignature.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.error = null;
            })

            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                state.login = true;
                localStorage.setItem("Authorization", `${action.payload.token}`);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(profile.fulfilled, (state, action) => {
                state.user = action.payload.user
                localStorage.setItem("user", JSON.stringify(action.payload.user));
            })
            .addCase(profile.rejected, (state, action) => {
                state.user = "User not found";
                localStorage.removeItem("user");
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.error = null;
                localStorage.setItem("user", JSON.stringify(action.payload));
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { restoreLogin } = userSlice.actions;

export default userSlice.reducer;

