import React from 'react';
import Points from '../../Assets/rewards/Frame.svg';
import Right from '../../Assets/rewards/arrow-right.svg';
import Sync from '../../Assets/rewards/Sync.svg';
import Left from '../../Assets/rewards/arrow-left.svg';
import Users from '../../Assets/rewards/Users.png';

const RewardBanner = ({totalPoints}) => {
    return (
        <>
            <div className='text-white relative flex flex-row items-center justify-between font-2xl w-full rounded-[20px] border-[1px] border-[#282C31] pl-[7.5%] py-4 pr-[5%] mb-4'
                style={{
                    backgroundImage: `url(${Users})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >

                <img src={Left} alt='Arrow Left' className='absolute top-0 left-0 mt-6 ml-6' />

                <div className='flex flex-row gap-8 justify-center items-center'>
                    <img src={Points} className="mb-4" alt='Points' />

                    <div className='flex flex-col gap-2'>
                        <p className='font-gilroy text-2xl font-medium' style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>Total Reward Points</p>

                        <button className='flex flex-row items-center gap-8 justify-between bg-[#282C31] bg-opacity-50 text-[#D5D8DC] rounded-[12px] px-6 py-4' style={{ backgroundColor: "#00000066" }}>
                            <div className="flex items-center gap-2">
                                <p className="text-3xl font-bold font-gilroy" style={{ background: "linear-gradient(180deg, #EBECEE 30%, #868788 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>{totalPoints}</p>
                                <span className="text-lg font-semibold" style={{ background: "linear-gradient(180deg, #E900BA 0%, #830069 100%)", WebkitBackgroundClip: "text", color: "transparent" }}>XP</span>
                            </div>
                            <div>
                                <img src={Sync} alt='Arrow Right' />
                            </div>
                        </button>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <button className='flex items-center justify-between bg-[#282C31] bg-opacity-50 text-[#D5D8DC] rounded-[12px] px-4 py-2'>
                        Redeem Points
                        <img src={Right} alt='Arrow Right' className='ml-2 w-4 h-4' />
                    </button>
                    <button className='flex items-center justify-between bg-[#282C31] bg-opacity-50 text-[#D5D8DC] rounded-[12px] px-4 py-2'>
                        Convert to coins
                        <img src={Right} alt='Arrow Right' className='ml-2 w-4 h-4' />
                    </button>
                </div>

            </div>
        </>
    );
}

export default RewardBanner;
