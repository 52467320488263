import React, { useEffect } from 'react'
import Card from '../../Components/Card/Card'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventList, fetchPurchasedEvents } from '../../features/Events/EventSlice'
import NoData from '../../Components/Card/NoData'

const HomeContainer = ({ type }) => {


    const { status, events, purchasedEvents } = useSelector((state) => state.events)
    const dispatch = useDispatch()

    const getEvents = () => {
        if (status !== "loading") {
            dispatch(fetchPurchasedEvents()).then((result) => {
                console.log(result

                )
            })
        }
    }

    const getPurchasedEvents = () => {
        dispatch(fetchEventList()).then((result) => {
            console.log(result)
        })
    }

    useEffect(() => {
        if (events.length === 0) {
            getEvents()
        }
        if (purchasedEvents.length === 0) {
            getPurchasedEvents()
        }
    }, [])

    return (
        <>
            {type !== "all" ?
                <>
                    {events.map((value, index) => {
                        if(value?.is_cancelled)
                            {
                              return ("")
                            }
                        return (
                            <Card data={value} type={type} />
                        )
                    })}
                </> :
                <>
                    <>
                        {
                            purchasedEvents.length === 0 &&
                            <NoData/>
                        }
                    </>
                    <>
                        {purchasedEvents.map((value, index) => {
                            if(value?.is_cancelled)
                                {
                                  return ("")
                                }
                            return (
                                <Card data={value} type={type} />
                            )
                        })}
                    </>
                </>
            }
        </>
    )
}

export default HomeContainer