import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import card from "@material-tailwind/react/theme/components/card";

const ProfileLinkCard = ({ profileData }) => {
  const date = new Date(profileData?.created_at);
  const data = [
    {
      key: "Website",
      value: profileData?.websiteURL,
      icon: <i class="fa-solid fa-globe"></i>,
    },
    {
      key: "Email",
      value: profileData?.email,
      icon: <i class="fa-regular fa-envelope"></i>,
    },
    {
      key: "Phone",
      value: profileData?.contact_number,
      icon: <i class="fa-regular fa-envelope"></i>,
    },
    {
      key: "Joined in",
      value: `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`,
      icon: <i class="fa-solid fa-calendar-days"></i>,
    },
  ];
  return (
    <Card className="mt-6 w-[88vw] md:w-3/4 lg:w-[40%] bg-profile border-profile bg-opacity-40 p-4">
      <CardBody>
        <Typography variant="h5" color="white" className="mb-2">
          <span style={{ color: "#C568FF" }}>Information</span>
        </Typography>
        <div className="flex flex-col gap-4">
          {data.map((value, index) => {
            return (
              <div key={index} className="flex justify-between">
                <div className="flex items-center gap-2">
                  {value.icon}
                  <span>{value.key}</span>
                </div>
                <div className="font-public-sans text-white">
                  <span>{value.value}</span>
                </div>
              </div>
            );
          })}
        </div>
      </CardBody>
      <CardFooter className="pt-0"></CardFooter>
    </Card>
  );
};

export default ProfileLinkCard;
