import React from "react";
import {
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { makeRequest } from "../../api/api";
import { approveByAdmin, insertTicket } from "../../features/Events/EventSlice";
import { useDispatch } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import arrow from "../../Assets/SVG/arrow.svg";
import "./TicketAndPaymentForm.css";

function TicketAndPaymentForm() {
  const TicketType = () => {
    const [formdata, setFormdata] = React.useState({
      event_id: localStorage.getItem("RecentlyCreatedEvent"),
      nft_ticket_name: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      description: "",
      event_type: "",
      price: 0,
      total_slots: 0,
      active_slots: 0,
      currency: "USD",
      timezone: "",
      nft_image_url: "",
      event_registration_entry: "Free",
      event_registration_status: "Open",
    });

    const [errors, setErrors] = React.useState({});
    const [hasErrors, setHasErrors] = React.useState(false);
    const navigate = useNavigate();
    const imageUpload = async (fileInput) => {
      const formdata = new FormData();
      formdata.append(
        "image",
        fileInput,
        "/C:/Users/Valia/Downloads/quantum_computing.jpeg"
      );
      formdata.append("entity", "banner");
      const data = await makeRequest(
        "POST",
        "apps/events/upload-image/",
        formdata
      );

      return data.data.message;
    };

    const handleFormChange = async (event) => {
      const { name, value, checked, type, files } = event.target;
      if (type === "file" && files.length > 0) {
        const fileUrl = await imageUpload(files[0]);
        setFormdata((prevData) => ({
          ...prevData,
          [name]: fileUrl,
        }));
      } else if (type === "checkbox") {
        setFormdata((prevData) => ({
          ...prevData,
          [name]: checked ? "Free" : "Paid",
        }));
      } else {
        setFormdata((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    };

    const checkRequiredFields = () => {
      const newErrors = {};
      if (!formdata.nft_ticket_name)
        newErrors.nft_ticket_name = "NFT Ticket Name is required";
      if (!formdata.start_date) newErrors.start_date = "Start Date is required";
      if (!formdata.end_date) newErrors.end_date = "End Date is required";
      if (!formdata.start_time) newErrors.start_time = "Start Time is required";
      if (!formdata.end_time) newErrors.end_time = "End Time is required";
      if (!formdata.description)
        newErrors.description = "NFT Description is required";
      if (!formdata.price) newErrors.price = "Price is required";
      if (!formdata.total_slots)
        newErrors.total_slots = "Total Slots are required";
      if (!formdata.timezone) newErrors.timezone = "Timezone is required";
      if (!formdata.nft_image_url)
        newErrors.nft_image_url = "NFT Image URL is required";
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const dispatch = useDispatch();

    const publishEvent = async () => {
      if (checkRequiredFields()) {
        const obj = {
          ...formdata,
          event_id: localStorage.getItem("RecentlyCreatedEvent"),
        };
        toast("🦄 Loading", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
        dispatch(insertTicket(obj)).then(async (result) => {
          console.log(result, "insertTicket result");
          dispatch(approveByAdmin());
          toast("🦄 Ticket Created Successfully!!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
          await new Promise((resolve) => setTimeout(resolve, 5000));
          navigate("/");
          window.location.reload();
        });
        setHasErrors(false);
      } else {
        setHasErrors(true);
      }
    };
    const getInputStyle = (fieldName) => {
      return errors[fieldName] ? "border-red-500" : "border-[#727C8A30]";
    };

    console.log(errors, formdata);
    return (
      <div>
        <div className="lg:grid-cols-3 flex flex-col mt-2 grid gap-4 font-semibold">
          <div className="w-full border rounded-xl p-2 border-[#727C8A30] bg-[#282C31] bg-opacity-30">
            <div className="flex w-full text-white text-lg justify-between">
              <h2>
                Event Registration
                <br /> Status
              </h2>
              <label
                for="dropzone-file"
                class="flex float-right flex-col items-center justify-center w-10 h-10 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-5">
                  <svg
                    width="29"
                    height="30"
                    viewBox="0 0 29 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.6302 6.69027C20.6302 6.70208 20.6302 6.70208 20.6302 6.71389C20.3351 6.70208 20.0399 6.69027 19.7211 6.69027H10.6301L11.8934 5.43879C13.0505 4.26995 14.3256 3.19556 15.8014 3.19556C17.289 3.19556 18.5641 4.26995 19.7211 5.43879L20.3823 6.09995C20.5476 6.25343 20.6302 6.46595 20.6302 6.69027Z"
                      fill="url(#paint0_linear_1292_376)"
                    />
                    <path
                      d="M24.9396 16.3834C25.4 16.3834 25.766 16.0056 25.766 15.5333V14.4944C25.766 9.85443 24.3492 8.44946 19.7211 8.44946H12.283H9.28417C4.65603 8.44946 3.23926 9.86624 3.23926 14.4944V15.0021C3.23926 15.4743 3.60526 15.8403 4.06571 15.8403C5.05746 15.8403 5.84849 16.6432 5.84849 17.6231C5.84849 18.603 5.05746 19.4177 4.06571 19.4177C3.60526 19.4177 3.23926 19.7837 3.23926 20.2559V20.7636C3.23926 25.4036 4.65603 26.8085 9.28417 26.8085H12.2712H19.7093C24.3374 26.8085 25.7542 25.3918 25.7542 20.7636C25.7542 20.3032 25.3882 19.9254 24.9278 19.9254C23.936 19.9254 23.145 19.1343 23.145 18.1544C23.1568 17.1745 23.9478 16.3834 24.9396 16.3834ZM13.1095 23.1013C13.1095 23.5618 12.7317 23.9396 12.2712 23.9396C11.8108 23.9396 11.433 23.5618 11.433 23.1013V19.9372C11.433 19.4767 11.8108 19.0989 12.2712 19.0989C12.7317 19.0989 13.1095 19.4767 13.1095 19.9372V23.1013ZM13.1095 15.3208C13.1095 15.7813 12.7317 16.1591 12.2712 16.1591C11.8108 16.1591 11.433 15.7813 11.433 15.3208V12.1567C11.433 11.6962 11.8108 11.3184 12.2712 11.3184C12.7317 11.3184 13.1095 11.6962 13.1095 12.1567V15.3208Z"
                      fill="url(#paint1_linear_1292_376)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1292_376"
                        x1="15.6302"
                        y1="3.19556"
                        x2="15.6302"
                        y2="6.71389"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_1292_376"
                        x1="14.5026"
                        y1="8.44946"
                        x2="14.5026"
                        y2="26.8085"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </label>
            </div>

            <button
              id="dropdownRadioBgHoverButton"
              data-dropdown-toggle="dropdownRadioBgHover"
              class="flex text-white bg-[#282C31] bg-opacity-30 font-medium rounded-lg text-sm my-2 px-3 py-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              {formdata.event_registration_status}{" "}
              <svg
                class="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <div
              id="dropdownRadioBgHover"
              class="z-10 hidden w-32 bg-[#393E45] divide-y divide-gray-100 rounded-lg shadow dark:bg-[#393E45] dark:divide-gray-600"
            >
              <ul
                class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownRadioBgHoverButton"
              >
                <li>
                  <div class="flex items-center p-2 rounded hover:bg-gray-100  dark:hover:bg-gray-600">
                    <input
                      id="default-radio-4"
                      type="radio"
                      value="Open"
                      name="event_registration_status"
                      onChange={handleFormChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="default-radio-4"
                      class="w-full ms-2 text-sm hover:text-black font-medium text-white rounded dark:text-gray-300"
                    >
                      Open
                    </label>
                  </div>
                </li>
                <li>
                  <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input
                      checked
                      id="default-radio-5"
                      type="radio"
                      value="On Hold"
                      name="event_registration_status"
                      onChange={handleFormChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="default-radio-5"
                      class="w-full ms-2 text-sm hover:text-black font-medium text-white rounded dark:text-gray-300"
                    >
                      On Hold
                    </label>
                  </div>
                </li>
                <li>
                  <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input
                      id="default-radio-6"
                      type="radio"
                      value="Scheduled"
                      name="event_registration_status"
                      onChange={handleFormChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="default-radio-6"
                      class="w-full ms-2 text-sm hover:text-black font-medium text-white rounded dark:text-gray-300"
                    >
                      Scheduled
                    </label>
                  </div>
                </li>
                <li>
                  <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input
                      id="default-radio-6"
                      type="radio"
                      value="Closed"
                      name="event_registration_status"
                      onChange={handleFormChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="default-radio-6"
                      class="w-full ms-2 text-sm hover:text-black font-medium text-white rounded dark:text-gray-300"
                    >
                      Closed
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full border rounded-xl p-2 border-[#727C8A30] bg-[#282C31] bg-opacity-30">
            <div className="flex w-full text-white text-lg justify-between">
              <h2>
                Event Capacity
                <br /> Limit
              </h2>
              <label
                for="dropzone-file"
                class="flex float-right flex-col items-center justify-center w-10 h-10 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-5">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.7499 10.8762C24.7087 10.8762 24.6812 10.8762 24.6399 10.8762H24.5712C21.9724 10.7937 20.0337 8.78617 20.0337 6.31117C20.0337 3.78117 22.0962 1.73242 24.6124 1.73242C27.1287 1.73242 29.1912 3.79492 29.1912 6.31117C29.1774 8.79992 27.2387 10.8074 24.7637 10.8899C24.7637 10.8762 24.7637 10.8762 24.7499 10.8762ZM24.6124 3.78118C23.2237 3.78118 22.0962 4.90868 22.0962 6.29743C22.0962 7.65868 23.1549 8.75868 24.5162 8.81368C24.5299 8.79993 24.6399 8.79993 24.7637 8.81368C26.0974 8.74493 27.1287 7.64493 27.1424 6.29743C27.1424 4.90868 26.0149 3.78118 24.6124 3.78118Z"
                      fill="url(#paint0_linear_1292_294)"
                    />
                    <path
                      d="M24.7637 21.0101C24.2274 21.0101 23.6912 20.9689 23.1549 20.8726C22.5912 20.7764 22.2199 20.2401 22.3162 19.6764C22.4124 19.1126 22.9487 18.7414 23.5124 18.8376C25.2037 19.1264 26.9912 18.8102 28.1874 18.0127C28.8337 17.5864 29.1774 17.0501 29.1774 16.5139C29.1774 15.9776 28.8199 15.4551 28.1874 15.0289C26.9912 14.2314 25.1762 13.9151 23.4712 14.2176C22.9074 14.3276 22.3712 13.9426 22.2749 13.3789C22.1787 12.8151 22.5499 12.2789 23.1137 12.1826C25.3549 11.7839 27.6787 12.2101 29.3287 13.3101C30.5387 14.1214 31.2399 15.2764 31.2399 16.5139C31.2399 17.7376 30.5524 18.9064 29.3287 19.7314C28.0774 20.5564 26.4549 21.0101 24.7637 21.0101Z"
                      fill="url(#paint1_linear_1292_294)"
                    />
                    <path
                      d="M8.20891 10.8763C8.19516 10.8763 8.18141 10.8763 8.18141 10.8763C5.70641 10.7938 3.76766 8.78624 3.75391 6.31124C3.75391 3.78124 5.81641 1.71875 8.33266 1.71875C10.8489 1.71875 12.9114 3.78125 12.9114 6.2975C12.9114 8.78625 10.9727 10.7938 8.49766 10.8763L8.20891 9.84501L8.30516 10.8763C8.27766 10.8763 8.23641 10.8763 8.20891 10.8763ZM8.34641 8.81376C8.42891 8.81376 8.49766 8.81375 8.58016 8.8275C9.80391 8.7725 10.8764 7.67249 10.8764 6.31124C10.8764 4.92249 9.74891 3.79499 8.36016 3.79499C6.97141 3.79499 5.84391 4.92249 5.84391 6.31124C5.84391 7.65874 6.88891 8.745 8.22266 8.8275C8.23641 8.81375 8.29141 8.81376 8.34641 8.81376Z"
                      fill="url(#paint2_linear_1292_294)"
                    />
                    <path
                      d="M8.195 21.0101C6.50375 21.0101 4.88125 20.5564 3.63 19.7314C2.42 18.9202 1.71875 17.7514 1.71875 16.5139C1.71875 15.2901 2.42 14.1214 3.63 13.3101C5.28 12.2101 7.60375 11.7839 9.845 12.1826C10.4087 12.2789 10.78 12.8151 10.6838 13.3789C10.5875 13.9426 10.0513 14.3276 9.4875 14.2176C7.7825 13.9151 5.98125 14.2314 4.77125 15.0289C4.125 15.4551 3.78125 15.9776 3.78125 16.5139C3.78125 17.0501 4.13875 17.5864 4.77125 18.0127C5.9675 18.8102 7.755 19.1264 9.44625 18.8376C10.01 18.7414 10.5463 19.1264 10.6425 19.6764C10.7388 20.2401 10.3675 20.7764 9.80375 20.8726C9.2675 20.9689 8.73125 21.0101 8.195 21.0101Z"
                      fill="url(#paint3_linear_1292_294)"
                    />
                    <path
                      d="M16.4999 21.1474C16.4587 21.1474 16.4312 21.1474 16.3899 21.1474H16.3212C13.7224 21.0649 11.7837 19.0574 11.7837 16.5824C11.7837 14.0524 13.8462 12.0037 16.3624 12.0037C18.8787 12.0037 20.9412 14.0662 20.9412 16.5824C20.9274 19.0712 18.9887 21.0787 16.5137 21.1612C16.5137 21.1474 16.5137 21.1474 16.4999 21.1474ZM16.3624 14.0524C14.9737 14.0524 13.8462 15.1799 13.8462 16.5687C13.8462 17.9299 14.9049 19.0299 16.2662 19.0849C16.2799 19.0712 16.3899 19.0712 16.5137 19.0849C17.8474 19.0162 18.8787 17.9162 18.8924 16.5687C18.8924 15.1937 17.7649 14.0524 16.3624 14.0524Z"
                      fill="url(#paint4_linear_1292_294)"
                    />
                    <path
                      d="M16.4998 31.295C14.8498 31.295 13.1998 30.8688 11.921 30.0025C10.711 29.1913 10.0098 28.0363 10.0098 26.7988C10.0098 25.575 10.6973 24.3925 11.921 23.5812C14.4923 21.8762 18.521 21.8762 21.0785 23.5812C22.2885 24.3925 22.9898 25.5475 22.9898 26.785C22.9898 28.0087 22.3023 29.1913 21.0785 30.0025C19.7998 30.855 18.1498 31.295 16.4998 31.295ZM13.0623 25.3138C12.416 25.74 12.0723 26.2762 12.0723 26.8125C12.0723 27.3487 12.4298 27.8713 13.0623 28.2975C14.9185 29.5488 18.0673 29.5488 19.9235 28.2975C20.5698 27.8713 20.9135 27.335 20.9135 26.7988C20.9135 26.2625 20.556 25.74 19.9235 25.3138C18.081 24.0625 14.9323 24.0763 13.0623 25.3138Z"
                      fill="url(#paint5_linear_1292_294)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1292_294"
                        x1="24.6124"
                        y1="1.73242"
                        x2="24.6124"
                        y2="10.8899"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_1292_294"
                        x1="26.7499"
                        y1="12.0359"
                        x2="26.7499"
                        y2="21.0101"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_1292_294"
                        x1="8.33266"
                        y1="1.71875"
                        x2="8.33266"
                        y2="10.8763"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_1292_294"
                        x1="6.20884"
                        y1="12.0359"
                        x2="6.20884"
                        y2="21.0101"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_1292_294"
                        x1="16.3624"
                        y1="12.0037"
                        x2="16.3624"
                        y2="21.1612"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint5_linear_1292_294"
                        x1="16.4998"
                        y1="22.3025"
                        x2="16.4998"
                        y2="31.295"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </label>
            </div>
            <div class="relative flex my-2 items-center max-w-[8rem]">
              <button
                type="button"
                id="decrement-button"
                data-input-counter-decrement="quantity-input"
                class="bg-[#393E45] dark:bg-gray-700 dark:hover:bg-gray-600 border-0 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-10 focus:ring-[#393E45] dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
              >
                <svg
                  class="w-3 h-3 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                name="total_slots"
                value={formdata.total_slots}
                onChange={handleFormChange}
                id="quantity-input"
                className={`bg-[#282C31] bg-opacity-30 border-x-0 border ${getInputStyle(
                  "total_slots"
                )} h-10 text-center text-white text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:placeholder-gray-400 dark:text-white`}
                placeholder="250"
                required
              />
              <button
                type="button"
                id="increment-button"
                data-input-counter-increment="quantity-input"
                class="bg-[#393E45] dark:bg-gray-700 dark:hover:bg-gray-600 border-0 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-10 focus:ring-[#393E45] dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
              >
                <svg
                  class="w-3 h-3 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="w-full border rounded-xl p-2 h-full border-[#727C8A30] bg-[#282C31] bg-opacity-30">
            <div className="flex w-full text-white text-lg justify-between">
              <h2>
                Event Registration
                <br /> Entry
              </h2>
              <label
                for="dropzone-file"
                class="flex float-right flex-col items-center justify-center w-10 h-10 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-5">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.7499 10.8762C24.7087 10.8762 24.6812 10.8762 24.6399 10.8762H24.5712C21.9724 10.7937 20.0337 8.78617 20.0337 6.31117C20.0337 3.78117 22.0962 1.73242 24.6124 1.73242C27.1287 1.73242 29.1912 3.79492 29.1912 6.31117C29.1774 8.79992 27.2387 10.8074 24.7637 10.8899C24.7637 10.8762 24.7637 10.8762 24.7499 10.8762ZM24.6124 3.78118C23.2237 3.78118 22.0962 4.90868 22.0962 6.29743C22.0962 7.65868 23.1549 8.75868 24.5162 8.81368C24.5299 8.79993 24.6399 8.79993 24.7637 8.81368C26.0974 8.74493 27.1287 7.64493 27.1424 6.29743C27.1424 4.90868 26.0149 3.78118 24.6124 3.78118Z"
                      fill="url(#paint0_linear_1292_294)"
                    />
                    <path
                      d="M24.7637 21.0101C24.2274 21.0101 23.6912 20.9689 23.1549 20.8726C22.5912 20.7764 22.2199 20.2401 22.3162 19.6764C22.4124 19.1126 22.9487 18.7414 23.5124 18.8376C25.2037 19.1264 26.9912 18.8102 28.1874 18.0127C28.8337 17.5864 29.1774 17.0501 29.1774 16.5139C29.1774 15.9776 28.8199 15.4551 28.1874 15.0289C26.9912 14.2314 25.1762 13.9151 23.4712 14.2176C22.9074 14.3276 22.3712 13.9426 22.2749 13.3789C22.1787 12.8151 22.5499 12.2789 23.1137 12.1826C25.3549 11.7839 27.6787 12.2101 29.3287 13.3101C30.5387 14.1214 31.2399 15.2764 31.2399 16.5139C31.2399 17.7376 30.5524 18.9064 29.3287 19.7314C28.0774 20.5564 26.4549 21.0101 24.7637 21.0101Z"
                      fill="url(#paint1_linear_1292_294)"
                    />
                    <path
                      d="M8.20891 10.8763C8.19516 10.8763 8.18141 10.8763 8.18141 10.8763C5.70641 10.7938 3.76766 8.78624 3.75391 6.31124C3.75391 3.78124 5.81641 1.71875 8.33266 1.71875C10.8489 1.71875 12.9114 3.78125 12.9114 6.2975C12.9114 8.78625 10.9727 10.7938 8.49766 10.8763L8.20891 9.84501L8.30516 10.8763C8.27766 10.8763 8.23641 10.8763 8.20891 10.8763ZM8.34641 8.81376C8.42891 8.81376 8.49766 8.81375 8.58016 8.8275C9.80391 8.7725 10.8764 7.67249 10.8764 6.31124C10.8764 4.92249 9.74891 3.79499 8.36016 3.79499C6.97141 3.79499 5.84391 4.92249 5.84391 6.31124C5.84391 7.65874 6.88891 8.745 8.22266 8.8275C8.23641 8.81375 8.29141 8.81376 8.34641 8.81376Z"
                      fill="url(#paint2_linear_1292_294)"
                    />
                    <path
                      d="M8.195 21.0101C6.50375 21.0101 4.88125 20.5564 3.63 19.7314C2.42 18.9202 1.71875 17.7514 1.71875 16.5139C1.71875 15.2901 2.42 14.1214 3.63 13.3101C5.28 12.2101 7.60375 11.7839 9.845 12.1826C10.4087 12.2789 10.78 12.8151 10.6838 13.3789C10.5875 13.9426 10.0513 14.3276 9.4875 14.2176C7.7825 13.9151 5.98125 14.2314 4.77125 15.0289C4.125 15.4551 3.78125 15.9776 3.78125 16.5139C3.78125 17.0501 4.13875 17.5864 4.77125 18.0127C5.9675 18.8102 7.755 19.1264 9.44625 18.8376C10.01 18.7414 10.5463 19.1264 10.6425 19.6764C10.7388 20.2401 10.3675 20.7764 9.80375 20.8726C9.2675 20.9689 8.73125 21.0101 8.195 21.0101Z"
                      fill="url(#paint3_linear_1292_294)"
                    />
                    <path
                      d="M16.4999 21.1474C16.4587 21.1474 16.4312 21.1474 16.3899 21.1474H16.3212C13.7224 21.0649 11.7837 19.0574 11.7837 16.5824C11.7837 14.0524 13.8462 12.0037 16.3624 12.0037C18.8787 12.0037 20.9412 14.0662 20.9412 16.5824C20.9274 19.0712 18.9887 21.0787 16.5137 21.1612C16.5137 21.1474 16.5137 21.1474 16.4999 21.1474ZM16.3624 14.0524C14.9737 14.0524 13.8462 15.1799 13.8462 16.5687C13.8462 17.9299 14.9049 19.0299 16.2662 19.0849C16.2799 19.0712 16.3899 19.0712 16.5137 19.0849C17.8474 19.0162 18.8787 17.9162 18.8924 16.5687C18.8924 15.1937 17.7649 14.0524 16.3624 14.0524Z"
                      fill="url(#paint4_linear_1292_294)"
                    />
                    <path
                      d="M16.4998 31.295C14.8498 31.295 13.1998 30.8688 11.921 30.0025C10.711 29.1913 10.0098 28.0363 10.0098 26.7988C10.0098 25.575 10.6973 24.3925 11.921 23.5812C14.4923 21.8762 18.521 21.8762 21.0785 23.5812C22.2885 24.3925 22.9898 25.5475 22.9898 26.785C22.9898 28.0087 22.3023 29.1913 21.0785 30.0025C19.7998 30.855 18.1498 31.295 16.4998 31.295ZM13.0623 25.3138C12.416 25.74 12.0723 26.2762 12.0723 26.8125C12.0723 27.3487 12.4298 27.8713 13.0623 28.2975C14.9185 29.5488 18.0673 29.5488 19.9235 28.2975C20.5698 27.8713 20.9135 27.335 20.9135 26.7988C20.9135 26.2625 20.556 25.74 19.9235 25.3138C18.081 24.0625 14.9323 24.0763 13.0623 25.3138Z"
                      fill="url(#paint5_linear_1292_294)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1292_294"
                        x1="24.6124"
                        y1="1.73242"
                        x2="24.6124"
                        y2="10.8899"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_1292_294"
                        x1="26.7499"
                        y1="12.0359"
                        x2="26.7499"
                        y2="21.0101"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_1292_294"
                        x1="8.33266"
                        y1="1.71875"
                        x2="8.33266"
                        y2="10.8763"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_1292_294"
                        x1="6.20884"
                        y1="12.0359"
                        x2="6.20884"
                        y2="21.0101"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_1292_294"
                        x1="16.3624"
                        y1="12.0037"
                        x2="16.3624"
                        y2="21.1612"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                      <linearGradient
                        id="paint5_linear_1292_294"
                        x1="16.4998"
                        y1="22.3025"
                        x2="16.4998"
                        y2="31.295"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BDC2C9" />
                        <stop offset="1" stop-color="#5D6063" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </label>
            </div>
            <div class="inline-flex items-center my-3">
              <div class="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                <input
                  defaultChecked
                  name="event_registration_entry"
                  onChange={handleFormChange}
                  id="switch-3"
                  type="checkbox"
                  class="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-green-500 peer-checked:border-green-500 peer-checked:before:bg-green-500"
                />
                <label
                  htmlFor="switch-3"
                  class="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-green-500 peer-checked:before:bg-green-500"
                >
                  <div
                    class="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                    data-ripple-dark="true"
                  ></div>
                </label>
              </div>
              <label
                htmlFor="auto-update"
                class="mt-px mb-0 ml-3 font-light text-white cursor-pointer select-none"
              >
                {formdata.event_registration_entry}
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-col py-5 mt-5 w-full text-[#BDC2C9] rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:max-w-full">
          <div className="flex flex-col px-9 w-full max-md:px-5 max-md:max-w-full">
            <h1 className="text-xl font-bold ">Ticket Details</h1>
            <label
              htmlFor="timeZone"
              className="mt-6 text-base font-semibold text-neutral-300 max-md:max-w-full"
            >
              Time Zone*
            </label>
            <select
              id="timezones"
              name="timezone"
              onChange={handleFormChange}
              className={`inpt-field w-1/2 appearance-none outline-neutral-700 flex bg-transparent gap-5 self-start px-5 py-3.5 mt-4 text-base rounded-xl border border-solid bg-zinc-800 text-neutral-300 ${
                errors.timezone ? "border-red-500" : "border-[#727C8A30]"
              }`}
              required
            >
              <option value="America/New_York">EST - GMT-5 (New York)</option>
              <option value="America/Los_Angeles">
                PST - GMT-8 (Los Angeles)
              </option>
              <option value="Europe/London">GMT - GMT+0 (London)</option>
              <option value="Europe/Paris">CET - GMT+1 (Paris)</option>
              <option value="Asia/Tokyo">JST - GMT+9 (Tokyo)</option>
              <option value="Australia/Sydney">AEDT - GMT+11 (Sydney)</option>
              <option value="Canada/Mountain">MST - GMT-7 (Canada)</option>
              <option value="Canada/Central">CST - GMT-6 (Canada)</option>
              <option value="Canada/Eastern">EST - GMT-5 (Canada)</option>
              <option value="Europe/Berlin">CET - GMT+1 (Berlin)</option>
              <option value="Asia/Dubai">GST - GMT+4 (Dubai)</option>
              <option value="Asia/Singapore">SGT - GMT+8 (Singapore)</option>
            </select>
            {errors.timezone && (
              <span className="text-red-500 text-xs mt-1">
                {errors.timezone}
              </span>
            )}
            <hr class="h-px my-8  mx-[-2.25rem] bg-[#282C31] border-0"></hr>
            <div className="mt-8 max-md:max-w-full">
              <div className="date-thing flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="time-labels flex flex-col grow font-white text-base font-semibold text-neutral-300 max-md:mt-10">
                    <label htmlFor="startDate">Start Date (DD-MM-YYYY)</label>
                    <input
                      type="date"
                      id="startDate"
                      name="start_date"
                      onChange={handleFormChange}
                      placeholder="12/04/2024"
                      className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                        errors.startDate
                          ? "border-red-500"
                          : "border-[#727C8A30] bg-zinc-800"
                      }`}
                    />
                    {errors.startDate && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.startDate}
                      </span>
                    )}

                    <label htmlFor="endDate" className="mt-6">
                      End Date (DD-MM-YYYY)
                    </label>
                    <input
                      type="date"
                      onChange={handleFormChange}
                      id="endDate"
                      name="end_date"
                      placeholder="12/04/2024"
                      className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                        errors.endDate
                          ? "border-red-500"
                          : "border-[#727C8A30] bg-zinc-800"
                      }`}
                    />
                    {errors.endDate && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.endDate}
                      </span>
                    )}
                  </div>
                </div>
                <div className="time-thingy flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="time-labels flex flex-col grow text-base font-semibold text-neutral-300 max-md:mt-10">
                    <label htmlFor="startTime">
                      Start Time (HH:MM) - 24 Hrs
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      name="start_time"
                      onChange={handleFormChange}
                      placeholder="22:00"
                      className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg dark:color-white font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                        errors.startTime
                          ? "border-red-500"
                          : "border-[#727C8A30] bg-zinc-800"
                      }`}
                    />
                    {errors.startTime && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.startTime}
                      </span>
                    )}

                    <label htmlFor="endTime" className="mt-6">
                      End Time (HH:MM) - 24 Hrs
                    </label>
                    <input
                      type="time"
                      id="endTime"
                      name="end_time"
                      onChange={handleFormChange}
                      placeholder="22:00"
                      className={`inpt-field bg-transparent h-[46px] justify-center items-start px-6 py-4 mt-3.5 text-lg font-medium whitespace-nowrap rounded-xl border border-solid max-md:px-5 ${
                        errors.endTime
                          ? "border-red-500"
                          : "border-[#727C8A30] bg-zinc-800"
                      }`}
                    />
                    {errors.endTime && (
                      <span className="text-red-500 text-xs mt-1">
                        {errors.endTime}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr class="h-px my-8  mx-[-2.25rem] bg-[#282C31] border-0"></hr>
            <div className="flex lg:flex-row flex-col w-[146vw] lg:w-full gap-10">
              <div class="relative w-1/2">
                <input
                  type="search"
                  id="search"
                  class="h-[46px] w-full p-4 ps-20 text-sm text-white border border-[#393E45] rounded-3xl bg-[#393E45] bg-opacity-30 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 placeholder-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  disabled
                  placeholder="Connect Payment with Stripe"
                  required
                />
                <button
                  type="submit"
                  class="text-white absolute start-1 bottom-1 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Stripe
                </button>
              </div>
              <div class="relative w-1/2">
                <input
                  type="search"
                  id="search"
                  class="h-[46px] w-full p-4 ps-20 text-sm text-white border border-[#393E45] rounded-3xl bg-[#393E45] bg-opacity-30 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 placeholder-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  disabled
                  placeholder="Connect with Web3 Wallet"
                  required
                />
                <button
                  type="submit"
                  class="text-white absolute start-1 bottom-1 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Wallet
                </button>
              </div>
            </div>
            <label
              htmlFor="currency-input"
              className="text-base font-semibold text-neutral-300 my-2"
            >
              Ticket Price
            </label>
            <form class="max-w-[18rem] flex">
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="number"
                  name="price"
                  value={formdata.price}
                  onChange={handleFormChange}
                  id="currency-input"
                  className={`block p-2.5 w-full z-20 ps-10 text-sm text-white bg-[#393E45] rounded-s-lg border-e-[#393E45] border-e-2 border ${getInputStyle(
                    "price"
                  )} focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-e-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500`}
                  placeholder="Enter amount"
                  required
                />
              </div>
              <select
                id="currency-selector"
                name="currency"
                onChange={handleFormChange}
                class="block py-2.5 px-4 text-sm font-medium text-white bg-[#393E45] bg-opacity-30 border border-[#393E45] rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="CAD">CAD</option>
                <option value="GBP">GBP</option>
              </select>
            </form>

            <hr class="h-px my-8  mx-[-2.25rem] bg-[#282C31] border-0"></hr>
            <h1 className="text-xl font-bold">Add NFT Ticket</h1>
            <div className="flex lg:flex-row flex-col items-start lg:items-end gap-5 my-5">
              <div className="lg:w-1/3 w-[74vw]">
                <label>Add Featured Image*</label>
                <p className="text-xs mb-2">
                  Image Size 400x400px | up to 10MB
                </p>
                <div class="flex justify-center w-full ">
                  <div className="flex items-center justify-center w-full">
                    <div
                      onClick={() =>
                        document.getElementById("fileInput2").click()
                      }
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border border-[#727C8A30] border-solid rounded-xl cursor-pointer bg-[#282C31] bg-opacity-30 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <input
                        type="file"
                        id="fileInput2"
                        accept="image/*"
                        name="nft_image_url"
                        onChange={handleFormChange}
                        className={`border ${getInputStyle("nft_image_url")}`}
                        style={{ display: "none" }}
                      />
                      {formdata?.nft_image_url !== "" ? (
                        <img
                          style={{ height: "100%" }}
                          src={formdata?.nft_image_url}
                          alt="Uploaded"
                        />
                      ) : (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 800x400px)
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-2/3 flex flex-col">
                <div className="w-full mb-5">
                  <label
                    htmlFor="timeZone"
                    className=" text-base text-sm font-semibold max-md:max-w-full"
                  >
                    Add Ticket Name
                  </label>
                  <input
                    type="text"
                    name="nft_ticket_name"
                    value={formdata.nft_ticket_name}
                    onChange={handleFormChange}
                    id="eventLocation"
                    placeholder="ABC WEB3 NFT"
                    className={`bg-transparent h-[46px] mt-2 lg:w-full w-[74vw] justify-center items-start px-6 py-4 text-lg font-medium whitespace-nowrap rounded-xl border ${getInputStyle(
                      "nft_ticket_name"
                    )} border-solid bg-zinc-800 max-md:px-5`}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="timeZone"
                    className=" text-base text-sm font-semibold max-md:max-w-full"
                  >
                    Add NFT Describition
                  </label>
                  <textarea
                    required
                    id="message"
                    name="description"
                    value={formdata.description}
                    onChange={handleFormChange}
                    rows="4"
                    className={`block bg-opacity-30 h-[80px] p-2.5 lg:w-full w-[74vw] h-[150px] text-sm bg-[#282C31] rounded-xl border ${getInputStyle(
                      "description"
                    )} border-solid bg-zinc-800 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Add Event Description"
                  />
                </div>
              </div>
            </div>
          </div>
          {hasErrors && (
            <div className="text-red-500 text-center mt-4">
              All fields are not filled, kindly fill the form above.
            </div>
          )}
          <div className="mt-16 flex justify-around lg:justify-between">
            <Button>Prev</Button>
            <Button
              onClick={publishEvent}
              style={{
                background: "linear-gradient(90deg, #A700E9 0%, #E900BA 100%)",
              }}
              className="text-white lg:w-full w-[40vw] md:w-[10rem] bg-[#74737333] h-[3rem] rounded-xl font-bold hover:bg-[#A700E9] transition duration-300"
            >
              Publish Event
            </Button>
          </div>
        </div>
      </div>
    );
  };


function ActionButton({ iconSrc, alt }) { 
  return ( 
    <button className="flex w-1/3 items-center bg-opacity-30 px-4 py-2 text-sm leading-5 text-center text-gray-300 bg-gray-600 rounded-xl"> 
      <img loading="lazy" src={iconSrc} alt={alt} className="shrink-0 w-6 aspect-square" /> 
      <span className="flex-auto ml-2">Add Discount Coupons</span>
    </button> 
  ); 
}

function CouponItem({ code, type, limit, sales }) { 
  return ( 
    <div className="flex gap-5 justify-between px-9 py-3 mt-2 w-full rounded-xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:flex-wrap max-md:px-5 max-md:max-w-full"> 
      <div className="flex gap-24 text-nowrap justify-between my-auto mr-40 text-base font-medium text-gray-200 max-md:flex-wrap max-md:max-w-full"> 
        <span className="ml-5   text-lg text-fuchsia-600 text-[#FF00CC]">{code}</span> 
        <span className="  ">{type}</span> 
        <span className="ml-5   ">{limit}</span> 
        <span className="ml-5    ">{sales}</span> 
      </div> 
      <div className="flex gap-4"> 
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/34c951d124b8d5b932d33952a0d711035821364442d5c6a3894b63cd6ed93412?apiKey=5da823a382734731b416a846873317ba&" alt="Edit" className="shrink-0 aspect-square w-[26px]" /> 
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ee246925955f7021c1f516acc80965e0776fcb97c16c63c31ed0db48c795166?apiKey=5da823a382734731b416a846873317ba&" alt="Delete" className="shrink-0 aspect-square w-[26px]" /> 
      </div> 
    </div> 
  ); 
}


    const Discount = () => {
        const coupons = [ 
            { code: "FREODQ", type: "50% OFF", limit: "N/A", sales: "150" }, 
          ];
       return(
        <div className="">
            <div className="border-[#282C31] border-b-[1px] pb-4">
            <section className="flex  flex-col items-end px-6 pt-6 pb-20 rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-w-[950px] max-md:px-5">
            <ActionButton iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/1aafb1b5a7739c1197e4ca417971c2007551a3182b62fee12a5078557407f75a?apiKey=5da823a382734731b416a846873317ba&" alt="Discount Coupon Icon" />
            <header className="flex w-full gap-20 px-11 py-4 mt-5 text-white font-medium rounded-t-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-50 text-neutral-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <h2>Coupon Code</h2>
            <h2>Offer Type</h2>
            <h2>Coupon Limit</h2>
            <h2 className="flex-auto">Sales</h2>
            </header>
            {coupons.map((coupon, index) => (
            <CouponItem key={index} {...coupon} />
            ))}
        </section>

        </div>
        <div className="mt-4 flex w-full items-end justify-between">
            <Button>Back</Button>
            <Button
                style={{
                    background:
                        "linear-gradient(90deg, #A700E9 0%, #E900BA 100%)",
                }}
                className="text-white w-full md:w-[10rem] bg-[#74737333] h-[3rem] rounded-xl font-bold hover:bg-[#A700E9] transition duration-300"
            >
                Save Changes
            </Button>
        </div>
        </div>
       )
    };

    const data = [
        {
            label: "Ticket Type",
            value: "TicketType",
            desc: <TicketType />,
        },
        {
            label: "Discount",
            value: "Discount",
            desc: <Discount />,
        },
    ];

  return (
    <div>
      <div className="flex text-[#D5D8DC] flex-col max-w-[950px]">
        <div className="dashbar flex gap-5 px-7 py-2.5 w-full rounded-2xl border border-[#636C7730] border-solid bg-[#282C31] bg-opacity-30 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex-auto my-auto text-[#727C8A] bg-transparent text-2xl font-semibold bg-clip-text">
            Ticket & Payments
          </div>
          <div className="links relative w-1/2 flex gap-3 py-2.5 pr-2 pl-5 text-sm rounded-2xl bg-[#282C31] bg-opacity-30">
            <div className="flex-auto my-auto text-neutral-300">
              https://abc.events/5irereward...
            </div>
            <Button
              size="sm"
              color="purple"
              className="!absolute right-1 top-1 rounded-xl "
            >
              Copy Url
            </Button>
          </div>
          <div className="arrow">
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <Tabs
          className="mt-5 w-[100vw] lg:w-full"
          id="custom-animation"
          value="TicketType"
        >
          <TabsHeader className="lg:w-2/5 w-[57vw] lg:mr-0 mr-[3vw] float-right bg-[#282C31] bg-opacity-30">
            {data.map(({ label, value }) => (
              <Tab key={value} value={value} /*activeClassName='bg-[#282C31]'*/>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody
            animate={{
              initial: { y: 250 },
              mount: { y: 0 },
              unmount: { y: 250 },
            }}
          >
            {data.map(({ value, desc }) => (
              <TabPanel key={value} value={value}>
                {desc}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
}

export default TicketAndPaymentForm;
